import React from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import { logout } from "../../store/actions/actions";

export const HeaderApp = ({ logout, isAuthenticated, user }) => {

  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
            <Button
              variant="dark"
              className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
              onClick={mobileSidebarToggle}
            >
              <i className="fas fa-ellipsis-v"></i>
            </Button>
            <Navbar.Brand
              href="/"
              onClick={(e) => e.preventDefault()}
              className="mr-2"
            >
              IMAX
            </Navbar.Brand>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
          </Navbar.Toggle>
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Navbar.Text>
                Atención a Clientes: <a href="tel:563-227-6202">563-227-6202</a><br />
                Whatsapp: <a href="https://wa.me/525632276202">563-227-6202</a>
              </Navbar.Text>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

// Connect react component with redux store
export const AdminNavbar = connect(mapStateToProps, { logout })(HeaderApp);
