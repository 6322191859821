import { combineReducers } from "redux";
import { auth, 

  myUserDetailsReducer,

  brandsList, 
  modelsList, 
  yearsList,
  pricesList, 
  constPricesList,

  dataReducer,

  quoteCreateReducer,
  
  quoteDetailsReducer,
  quoteListMyReducer,
  payOrderReducer, 
  cylinderList, 
  cfdiList,
  doorList,
  mileageList,
  motorsList,
  vehicleTypesList,
  statesList,
  stateOfficesList,
  cityCustomsList
} from "./reducers";

export default combineReducers({
  auth,

  userLogin: myUserDetailsReducer,

  brandsList,
  modelsList,
  yearsList,
  pricesList,
  constPricesList,

  order: dataReducer,
  quoteCreate: quoteCreateReducer,

  quoteDetails: quoteDetailsReducer,
  quoteListMy: quoteListMyReducer,
  payOrder: payOrderReducer,

  cylinderList,
  cfdiList,
  doorList,
  mileageList,
  motorsList,
  vehicleTypesList,
  statesList,
  stateOfficesList,
  cityCustomsList,
});
