import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

// redux connect with react
import { connect } from "react-redux";

import { Container, Button, Form, Spinner, FloatingLabel, Collapse } from 'react-bootstrap'

import { toast } from "react-toastify";

// Redux action
import { loadUser, listBrands, listModels, listYears, listPrices, createQuote, listCylinders, listVehicleTypes, listCityCustoms } from "../../store/actions/actions";
import * as css from '../../styles/app.css'

export const CarDataApp = ({ isAuthenticated, match }) => {

    //state constants
    const dispatch = useDispatch();
    let history = useHistory();

    const auth = useSelector(state => state.auth);
    const { user } = auth;

    const brandsList = useSelector(state => state.brandsList);
    const { brands } = brandsList;

    const modelsList = useSelector(state => state.modelsList);
    const { models } = modelsList;

    const yearsList = useSelector(state => state.yearsList);
    const { years } = yearsList;

    const pricesList = useSelector(state => state.pricesList);
    const { prices } = pricesList;

    const constPricesList = useSelector(state => state.constPricesList);
    const { cPrices } = constPricesList;

    const cylinderList = useSelector(state => state.cylinderList);
    const { cylinders } = cylinderList;

    const quoteListMy = useSelector(state => state.quoteListMy)
    const { loading: loadingQuotes, error: errorQuotes, quotes } = quoteListMy

    const vehicleTypesList = useSelector(state => state.vehicleTypesList);
    const { vehicleTypes } = vehicleTypesList;

    const cityCustomsList = useSelector(state => state.cityCustomsList);
    const { cityCustoms } = cityCustomsList;

    //state variables
    const [open, setOpen] = useState(false);
    const [requestSent, setRequestSent] = useState(false);
    const [brandId, setBrandId] = useState(null);
    const [modelId, setModelId] = useState(null);
    const [yearId, setYearId] = useState(null);
    const [tipoVehiculo, setTipoVehiculo] = useState("");
    const [cityCustom, setCityCustom] = useState(null);

    //user inputs
    const [nroCilindro, setNroCilindro] = useState(null);
    const [nroSerie, setNroSerie] = useState(null);
    const [autoLujo, setAutoLujo] = useState(false);
    const [ecoAuto, setEcoAuto] = useState(false);
    const [regimen, setRegimen] = useState("");
    const [price, setPrice] = useState(0);

    const totalPrice = (Number(price) * Number(cPrices.dolar_price)).toFixed(2);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (nroSerie !== "") {
            setRequestSent(true);
            // send state to redux

            const data = await dispatch(createQuote({
                brandId: brandId,
                modelId: modelId,
                yearId: yearId,
                price: totalPrice,
                cylinderId: nroCilindro,
                luxury_car: autoLujo,
                eco_car: ecoAuto,
                serial_number: nroSerie,
                regime: regimen,
                tipoVehiculo,
                cityCustomsId: cityCustom
            }));

            if (data?.orderId) {
                const message = "Hemos procesado tu solicitud. Puedes seguir con el proceso de cotización ahora o buscándolo después en tu perfil en 'Cotizaciones'.";

                setRequestSent(false);
                toast.success(message, {
                    hideProgressBar: true,
                    autoClose: 6000,
                });

                history.push(`/home/quote/${data.orderId}`)
            }
            else {
                toast.error(data)
                setRequestSent(false);
            }
        }
    };

    function isRegularizationAllowed() {
        console.log(user.role);
        if (user.role === "Gestor")
            return true;
        else {
            if (quotes.find(p => p.regime == "Regularización"))
                return false;
            else
                return true;
        }
    };

    useEffect(() => {
        dispatch(loadUser());
        dispatch(listBrands());
        dispatch(listVehicleTypes());
        dispatch(listCityCustoms())
    }, [dispatch]);

    useEffect(() => {
        //fetch models when a brand is selected
        if (brandId) {
            dispatch(listModels(brandId));
        }
    }, [dispatch, brandId])

    useEffect(() => {
        //fetch years when a model is selected
        if (modelId) {
            var model = models?.find(x => x.modelId === modelId);
            if (model) {
                setAutoLujo(model.isLuxury);
                setEcoAuto(model.isEco);
                dispatch(listYears());
            }
        }
    }, [dispatch, modelId, models]);

    useEffect(() => {
        //fetch price when a year is selected and handle changes of price for ecologic and luxury features
        if (yearId) {
            dispatch(listPrices(yearId));
            dispatch(listCylinders());
        }
    }, [dispatch, yearId]);

    useEffect(() => {
        if(!isRegularizationAllowed())
            regimen = "Definitivo";
        if (regimen && tipoVehiculo && prices && cylinders) {
            let calculatedPrice = 0;
            let regPrice = prices.find(p => p.type === regimen);
            let cilindro = cylinders.find(c => c.cylinderId === nroCilindro);
            if(regPrice && cilindro)
            switch (cilindro.name) {
                case "8 cilindros":
                    if (autoLujo && ecoAuto) calculatedPrice = regPrice.cylinder8ecoLuxury_price;
                    else if (autoLujo && !ecoAuto) calculatedPrice = regPrice.cylinder8luxury_price;
                    else if (!autoLujo && ecoAuto) calculatedPrice = regPrice.cylinder8eco_price;
                    else if (tipoVehiculo === "Pick-up") calculatedPrice = regPrice.cylinder8suv_price;
                    else calculatedPrice = regPrice.cylinder8_price;
                    break;
                case "6 cilindros":
                    if (autoLujo && ecoAuto) calculatedPrice = regPrice.cylinder6ecoLuxury_price;
                    else if (autoLujo && !ecoAuto) calculatedPrice = regPrice.cylinder6luxury_price;
                    else if (!autoLujo && ecoAuto) calculatedPrice = regPrice.cylinder6eco_price;
                    else if (tipoVehiculo === "Pick-up") calculatedPrice = regPrice.cylinder6suv_price;
                    else calculatedPrice = regPrice.cylinder6_price;
                    break;
                case "4 cilindros":
                    if (autoLujo && ecoAuto) calculatedPrice = regPrice.cylinder4ecoLuxury_price;
                    else if (autoLujo && !ecoAuto) calculatedPrice = regPrice.cylinder4luxury_price;
                    else if (!autoLujo && ecoAuto) calculatedPrice = regPrice.cylinder4eco_price;
                    else if (tipoVehiculo === "Pick-up") calculatedPrice = regPrice.cylinder4suv_price;
                    else calculatedPrice = regPrice.cylinder4_price;
                    break;
                default:
                    break;
            }
            setPrice(calculatedPrice);
        }
    }, [regimen, autoLujo, ecoAuto, nroCilindro, prices, tipoVehiculo]);



    return (

        <div >
            <Container>
                <div className="d-grid gap-2">
                    <Button
                        variant="success"
                        block
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                    >
                        Generar nueva cotización
                    </Button>
                </div>
            </Container>

            <Collapse in={open}>
                <Container className='mt-5'>
                    <Form onSubmit={(e) => handleSubmit(e)}>

                        <FloatingLabel controlId="floatingInput" label="Marca de vehículo" className="mb-4">
                            <Form.Control
                                as="select"
                                value={brandId ? brandId : ''}
                                placeholder='Selecciona una marca de vehículo...'
                                onChange={(e) => setBrandId(e.target.value)}
                            >
                                <option>Selecciona una marca de vehículo...</option>
                                {brands?.map((brand) =>
                                    <option value={brand.brandId}>
                                        {brand.name}
                                    </option>
                                )}
                            </Form.Control>
                        </FloatingLabel>

                        <FloatingLabel controlId="floatingInput" label="Modelo de vehículo" className="mb-4">
                            <Form.Control
                                as="select"
                                value={modelId ? modelId : ''}
                                onChange={(e) => setModelId(e.target.value)}
                                required
                            >
                                <option>Selecciona un modelo vehículo...</option>

                                {models?.map((model) =>
                                    <option value={model.modelId}>
                                        {model.name}
                                    </option>
                                )}

                            </Form.Control>
                        </FloatingLabel>

                        <FloatingLabel controlId="floatingInput" label="Año del vehículo" className="mb-4">
                            <Form.Control
                                as="select"
                                value={yearId ? yearId : ''}
                                onChange={(e) => setYearId(e.target.value)}
                                required
                            >
                                <option>Selecciona un año de vehículo...</option>

                                {years?.map((year) =>
                                    <option value={year.yearId}>
                                        {year.name}
                                    </option>
                                )}

                            </Form.Control>
                        </FloatingLabel>

                        <FloatingLabel controlId="floatingInput" label="Número de cilindros" className="mb-4">
                            <Form.Control
                                as="select"
                                onChange={(e) => setNroCilindro(e.target.value)}
                                required
                            >
                                <option>Selecciona el número de cilindros...</option>
                                {cylinders?.map((cylinder) =>
                                    <option value={cylinder.cylinderId}>
                                        {cylinder.name}
                                    </option>
                                )}
                            </Form.Control>
                        </FloatingLabel>

                        <FloatingLabel controlId="floatingInput" label="Tipo de Vehículo" className="mb-4">
                            <Form.Control
                                as="select"
                                onChange={(e) => setTipoVehiculo(e.target.value)}
                                required
                            >
                                <option>Selecciona el tipo...</option>
                                {vehicleTypes?.map((vehicleType) =>
                                    <option value={vehicleType.vehicleTypeId}>
                                        {vehicleType.name}
                                    </option>
                                )}
                            </Form.Control>
                        </FloatingLabel>

                        <FloatingLabel controlId="floatingInput" label="Definitivo o regularización (A1/A3)" className="mb-4">
                            {isRegularizationAllowed()
                                ? <Form.Control
                                    as="select"
                                    onChange={(e) => setRegimen(e.target.value)}
                                    required
                                >
                                    <option value=''>Ingresa el régimen de importación</option>
                                    <option value='Definitivo'>Definitivo</option>
                                    <option value='Regularización'>Regularización</option>
                                </Form.Control>
                                : <Form.Control
                                    as="select"
                                    readOnly>
                                    <option value='Definitivo'>Definitivo</option>
                                </Form.Control>}
                        </FloatingLabel>

                        <FloatingLabel controlId="floatingInput" label="Cantidad a Pagar" className="mb-4">
                            <Form.Control
                                type="text"
                                name="price"
                                value={totalPrice ? totalPrice + ' MXN' : '0.00 MXN'}
                                required
                            />
                            <Form.Text id="priceHelpBlock" muted>
                                <small>El precio final estará sujeto a la cotización del dólar en el día de la transacción.</small>
                            </Form.Text>
                        </FloatingLabel>


                        <FloatingLabel controlId="floatingInput" label="Correo electrónico" className="mb-5">
                            <Form.Control
                                type="email"
                                value={user.email ? user.email : ''} readOnly />
                        </FloatingLabel>

                        <FloatingLabel label="Número de serie" className="mb-3">
                            <Form.Control
                                type="text"
                                name="nroserie"
                                value={nroSerie || ""}
                                maxLength={17}
                                minLength={17}
                                onChange={(e) => setNroSerie(e.target.value)}
                                required
                                pattern='^[1,2,3,5].*$'
                            />
                            <Form.Text muted>
                                El número de serie debe contener 17 carácteres.
                            </Form.Text>
                        </FloatingLabel>

                        <FloatingLabel controlId="floatingInput" label="Aduana" className="mb-4">
                            <Form.Control
                                as="select"
                                onChange={(e) => setCityCustom(e.target.value)}
                                required
                            >
                                <option>Selecciona la aduana...</option>
                                {cityCustoms?.map((cityCustom) =>
                                    <option value={cityCustom.cityCustomsId}>
                                        {cityCustom.name}
                                    </option>
                                )}
                            </Form.Control>
                        </FloatingLabel>

                        <Form.Group className=" mt-5 mb-2 text-center">
                            {!requestSent ? (
                                <div className="d-grid gap-2">
                                    <Button
                                        variant="light"
                                        style={{ background: "#39A388" }}
                                        type="submit"
                                        className='btn-fill'
                                        block>
                                        Cotizar
                                    </Button>
                                </div>
                            ) : (
                                <Button
                                    variant="light"
                                    style={{ background: "#39A388" }}
                                    type="submit"
                                    className='btn-fill'
                                    block
                                    disabled>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {"  "}
                                    Generando cotización...
                                </Button>
                            )}
                        </Form.Group>
                    </Form>
                </Container>
            </Collapse>
        </div>
    )
}


// Get data from redux store
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated
});

// Connect react component with redux store
export const CarData = connect(mapStateToProps)(CarDataApp);

