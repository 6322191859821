import React, { useState } from "react";

// Router
import { Redirect, Link } from "react-router-dom";

// redux connect with react
import { connect } from "react-redux";

// Bootstrap
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";

import { toast } from "react-toastify";

// Redux action
import { login } from "../../store/actions/actions";

import * as css from '../../styles/app.css'

const LoginApp = ({ login, isAuthenticated }) => {
  const [formData, setformData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const handleChange = (e) =>
    setformData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // send state to redux
    const log = await login(email, password);

    if (log.status === 200) {
      const message = 'Inicio de sesión exitoso';

      toast.success(message, {
        hideProgressBar: true,
        autoClose: 4000,
      });
    } else {
      if (log.data?.errors && log.data.errors.length > 0) {
        toast.error(log.data.errors[0].message, {
          hideProgressBar: true,
          autoClose: 5000,
        });
      }
      else {
        toast.error('Datos de inicio de sesión incorrectos. Por favor, intenta de nuevo.');
      }
    }
  };

  // user IsAuthenticated
  // Redirect to homepage

  if (isAuthenticated) {
    return <Redirect to="/home" />;
  }

  return (
    <>
      {/* <Cube time={2000} /> */}
      <div className="page-header header-filter">
        <div className="pageContent d-flex align-items-center min-vh-100 py-3">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5} md={12}>
                <Card>
                  <div className="card-header card-header-primary text-center">
                    <Card.Title><h2 className='text-white'>Iniciar sesión</h2></Card.Title>
                  </div>
                  <Card.Body className="p-4">
                    <div className="text-center w-75 m-auto">

                      <p className="text-muted mb-4">
                        Ingresa tus datos para acceder a tu cuenta.
                      </p>
                    </div>
                    <Form onSubmit={(e) => handleSubmit(e)}>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="#email"><h5>Usuario</h5></Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Correo electrónico (ej. usuario@email.com)"
                          value={email || ""}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="py-3 mb-3">
                        <Link
                          to="/reset-password"
                          className="text-muted float-end"
                        >
                          <small>¿Olvidaste tu contraseña?</small>
                        </Link>
                        <Form.Label htmlFor="#password"><h5>Contraseña</h5></Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          id="password"
                          placeholder="Ingresar contraseña"
                          value={password || ""}
                          onChange={(e) => handleChange(e)}
                          minLength="8"
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mt-3 mb-3 text-center">
                        <Button className='text-dark' variant='secondary' type="submit" value="Login">
                          Ingresar
                        </Button>
                      </Form.Group>
                    </Form>
                    <div className="text-center mt-2">
                      <p className="text-muted">
                        ¿No tienes una cuenta?{" "}
                        <Link to="/signup" className="text-muted">Regístrate</Link>
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

// Get Data from redux store
const mapStateToProps = (state) => ({
  // Check Authentictaion
  isAuthenticated: state.auth.isAuthenticated,
});

// Connect react component with redux store
export const LoginPage = connect(mapStateToProps, { login })(LoginApp);
