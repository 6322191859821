import axios from 'axios'
import { toast } from 'react-toastify';
import {
  //AUTH CONSTANTS
  ACTIVATION_SUCCESS,
  ACTIVATION_FAILED,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAILED,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAILED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILED,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAILED,
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,

  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,

  //CARS CONSTANTS
  BRANDS_LIST_REQUEST,
  BRANDS_LIST_SUCCESS,
  BRANDS_LIST_FAIL,

  MODELS_LIST_REQUEST,
  MODELS_LIST_SUCCESS,
  MODELS_LIST_FAIL,

  YEARS_LIST_REQUEST,
  YEARS_LIST_SUCCESS,
  YEARS_LIST_FAIL,

  PRICES_LIST_REQUEST,
  PRICES_LIST_SUCCESS,
  PRICES_LIST_FAIL,

  PRICES_CONST_REQUEST,
  PRICES_CONST_SUCCESS,
  PRICES_CONST_FAIL,

  //ORDER ITEMS
  QUOTE_CREATE_REQUEST,
  QUOTE_CREATE_SUCCESS,
  QUOTE_CREATE_FAIL,

  ORDER_SAVE_DATA,

  QUOTE_DETAILS_REQUEST,
  QUOTE_DETAILS_SUCCESS,
  QUOTE_DETAILS_FAIL,

  QUOTE_LIST_MY_REQUEST,
  QUOTE_LIST_MY_SUCCESS,
  QUOTE_LIST_MY_FAIL,

  QUOTE_LIST_REQUEST,
  QUOTE_LIST_SUCCESS,
  QUOTE_LIST_FAIL,

  ORDER_PAID_REQUEST,
  ORDER_PAID_SUCCESS,
  ORDER_PAID_FAIL,

  CYLINDERS_LIST_REQUEST,
  CYLINDERS_LIST_SUCCESS,
  CYLINDERS_LIST_FAIL,

  CFDI_LIST_REQUEST,
  CFDI_LIST_SUCCESS,
  CFDI_LIST_FAIL,

  DOOR_LIST_REQUEST,
  DOOR_LIST_SUCCESS,
  DOOR_LIST_FAIL,

  MILEAGE_LIST_REQUEST,
  MILEAGE_LIST_SUCCESS,
  MILEAGE_LIST_FAIL,

  MOTORS_LIST_REQUEST,
  MOTORS_LIST_SUCCESS,
  MOTORS_LIST_FAIL,

  VEHICLETYPES_LIST_REQUEST,
  VEHICLETYPES_LIST_SUCCESS,
  VEHICLETYPES_LIST_FAIL,

  STATES_LIST_REQUEST,
  STATES_LIST_SUCCESS,
  STATES_LIST_FAIL,

  STATEOFFICES_LIST_REQUEST,
  STATEOFFICES_LIST_SUCCESS,
  STATEOFFICES_LIST_FAIL,

  CITYCUSTOMS_LIST_REQUEST,
  CITYCUSTOMS_LIST_SUCCESS,
  CITYCUSTOMS_LIST_FAIL,

} from "../actions/types";

// Check Authentication of Users, When user again loggedIn after some times.
export const checkAuthentication = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    try {
      const result = await axios.get(process.env.REACT_APP_API_ENDPOINT_URL + "/auth/jwt/verify/", config);

      if (result.data.code !== "token_not_valid") {
        dispatch({
          type: AUTHENTICATED_SUCCESS,
        });
        return true;
      } else {
        dispatch({
          type: AUTHENTICATED_FAILED,
        });
        return false;
      }
    } catch (err) {
      dispatch({
        type: AUTHENTICATED_FAILED,
      });
      return false;
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAILED,
    });
    return false;
  }
};

// Register a New Account for user
export const signup =
  (email, name, password, re_password) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const callBackUrl = 'https://app.imax.com.mx/activate';
    const body = JSON.stringify({ email, name, password, re_password, callBackUrl });

    try {
      const result = await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + "/auth/users/", body, config);

      const statusCode = result.status;

      if (statusCode === 201) {
        const { status, statusText } = result;
        const res = { status, statusText };
        dispatch({
          type: SIGNUP_SUCCESS,
        });
        return res;
      } else {
        const { status, statusText, data } = result;
        const res = { status, statusText, data };
        if (data && data.success) {
          dispatch({
            type: SIGNUP_SUCCESS,
          });
        }
        else {
          dispatch({
            type: SIGNUP_FAILED,
          });
        }
        return res;
      }
    } catch (err) {
      const { status, statusText, data } = err.response;

      const res = { status, statusText, data };
      dispatch({
        type: SIGNUP_FAILED,
      });
      return res;
    }
  };

// Activate a user account
export const activate = (uid, token) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ uid, token });

  try {
    const response = await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + "/auth/users/activation/", body, config);

    if (response.status === 204) {
      dispatch({
        type: ACTIVATION_SUCCESS,
      });
      return response.status;
    } else {
      dispatch({
        type: ACTIVATION_FAILED,
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: ACTIVATION_FAILED,
    });
    return false;
  }
};

// Login the existing user account
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email, password });

  try {
    const result = await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + "/auth/jwt/create/", body, config);
    const statusCode = result.status;

    if (statusCode === 200) {
      const { status, statusText } = result;
      const res = { status, statusText };
      dispatch({
        type: LOGIN_SUCCESS,
        payload: result.data,
      });
      dispatch(loadUser());
      dispatch(listMyQuotes());
      dispatch(getMyDetails());
      return res;
    } else {
      const { status, statusText, data } = result.response;
      const res = { status, statusText, data };

      dispatch({
        type: LOGIN_FAILED,
      });
      return res;
    }
  } catch (err) {
    const { status, statusText, data } = err.response;

    const res = { status, statusText, data };
    dispatch({
      type: LOGIN_FAILED,
    });
    return res;
  }
};

// Get User data after loggedIn
export const loadUser = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    try {
      const result = await axios.get(process.env.REACT_APP_API_ENDPOINT_URL + "/auth/users/me/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      });

      if (result.data?.success !== true) {
        dispatch({
          type: LOGOUT,
        });
        dispatch({
          type: USER_DETAILS_RESET,
        });
        return;
      }

      dispatch({
        type: LOAD_USER_SUCCESS,
        payload: result.data,
      });
    } catch (err) {
      dispatch({
        type: LOAD_USER_FAILED,
      });
    }
  } else {
    dispatch({
      type: LOAD_USER_FAILED,
    });
  }
};



export const getMyDetails = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST
    })

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("access")}`
      }
    }

    const { data } = await axios.get(
      process.env.REACT_APP_API_ENDPOINT_URL + `/auth/users/profile/`,
      config
    )

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data
    }); localStorage.setItem('userInfo', JSON.stringify(data));


  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: errorMessage
    });
  }
}

// Logout of user account
export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
  dispatch({
    type: USER_DETAILS_RESET,
  });
};

// Get reset password link of the user account
export const reset_password = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const callBackUrl = 'https://app.imax.com.mx/password/reset';
  const body = JSON.stringify({ emailAddress: email, callBackUrl });

  try {
    await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + "/auth/users/reset_password/", body, config);

    dispatch({
      type: PASSWORD_RESET_SUCCESS,
    });
    return true;
  } catch (err) {
    dispatch({
      type: PASSWORD_RESET_FAILED,
    });
    return false;
  }
};

// Reset the new password of user account
export const reset_password_confirm =
  (uid, token, new_password, re_new_password) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ uid, token, new_password, re_new_password });
    try {
      await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + "/auth/users/reset_password_confirm/", body, config);

      dispatch({
        type: PASSWORD_RESET_CONFIRM_SUCCESS,
      });
      return true;
    } catch (err) {
      dispatch({
        type: PASSWORD_RESET_CONFIRM_FAILED,
      });
      console.log(err.response);
      return false;
    }
  };

//Get the list of cars available
export const listBrands = () => async (dispatch) => {
  try {

    dispatch({ type: BRANDS_LIST_REQUEST })

    const { data } = await axios.get(process.env.REACT_APP_API_ENDPOINT_URL + '/api/cars/brand/')

    dispatch({
      type: BRANDS_LIST_SUCCESS,
      payload: data
    })

  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: BRANDS_LIST_FAIL,
      payload: errorMessage
    })
  }
}


export const listModels = (id) => async (dispatch) => {
  try {

    dispatch({ type: MODELS_LIST_REQUEST })

    const { data } = await axios.get(process.env.REACT_APP_API_ENDPOINT_URL + `/api/cars/brands/${id}`)

    dispatch({
      type: MODELS_LIST_SUCCESS,
      payload: data
    });
  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: MODELS_LIST_FAIL,
      payload: errorMessage
    })
  }
}

//Get the list of years and prior prices
export const listYears = () => async (dispatch) => {
  try {

    dispatch({ type: YEARS_LIST_REQUEST })

    const { data } = await axios.get(process.env.REACT_APP_API_ENDPOINT_URL + '/api/cars/year/')

    dispatch({
      type: YEARS_LIST_SUCCESS,
      payload: data
    })

  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: YEARS_LIST_FAIL,
      payload: errorMessage
    })
  }
}


export const listPrices = (id) => async (dispatch) => {
  try {

    dispatch({ type: PRICES_LIST_REQUEST })

    const { data } = await axios.get(process.env.REACT_APP_API_ENDPOINT_URL + `/api/cars/year/${id}/`)

    dispatch({
      type: PRICES_LIST_SUCCESS,
      payload: data
    })

  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: PRICES_LIST_FAIL,
      payload: errorMessage
    })
  }
}


//Last logged constants prices
export const listConstPrices = (id) => async (dispatch) => {
  try {

    dispatch({ type: PRICES_CONST_REQUEST })

    const { data } = await axios.get(process.env.REACT_APP_API_ENDPOINT_URL + `/api/const`)

    dispatch({
      type: PRICES_CONST_SUCCESS,
      payload: data
    })

  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: PRICES_CONST_FAIL,
      payload: errorMessage
    })
  }
}


//OrderItem
export const createQuote =
  (order) => async (dispatch, getState) => {

    try {
      const result = await axios.get(process.env.REACT_APP_API_ENDPOINT_URL + "/auth/users/me/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      });

      if (result.data?.success !== true) {
        dispatch({
          type: LOGOUT,
        });
        dispatch({
          type: USER_DETAILS_RESET,
        });
        return;
      }

      dispatch({
        type: QUOTE_CREATE_REQUEST
      })

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        }
      }

      const body = JSON.stringify(order);

      const { data } = await axios.post(
        process.env.REACT_APP_API_ENDPOINT_URL + `/api/orders`,
        body,
        config,
      )

      dispatch({
        type: QUOTE_CREATE_SUCCESS,
        payload: data
      });
      return data;

    } catch (error) {
      const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
        ? error.response.data.errors[0].message
        : error.message;
      dispatch({
        type: QUOTE_CREATE_FAIL,
        payload: errorMessage
      })
      return errorMessage;
    }
  };

export const updatePayment = (paymentRequest) => async (dispatch, getState) => {

  try {

    dispatch({
      type: ORDER_PAID_REQUEST
    })

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    }

    const body = JSON.stringify(paymentRequest);

    const { data } = await axios.put(
      process.env.REACT_APP_API_ENDPOINT_URL + `/api/orders/payment/${paymentRequest.transactionNumber}`,
      body,
      config
    )

    if (data.success) {
      const message =
        "Se actualizó el estado de tu cotización. Puedes ver los detalles ahora en 'Cotizaciones'.";

      toast.success(message, {
        hideProgressBar: true,
        autoClose: 6000,
      });
    }
    else {
      toast.error(`Ocurrió un error al guardar el pago, favor de comunicarse a servicio al cliente. Autorización: ${paymentRequest.authorizationNumber}`)
    }
    dispatch({
      type: ORDER_PAID_SUCCESS,
      payload: data
    });
    return data;
  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: ORDER_PAID_FAIL,
      payload: errorMessage
    })
    return errorMessage;
  }
};
//OrderItem id

export const getQuoteDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUOTE_DETAILS_REQUEST
    })

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("access")}`
      }
    }

    const { data } = await axios.get(
      process.env.REACT_APP_API_ENDPOINT_URL + `/api/orders/${id}`,
      config
    )

    dispatch({
      type: QUOTE_DETAILS_SUCCESS,
      payload: data
    })


  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: QUOTE_DETAILS_FAIL,
      payload: errorMessage
    })
  }
}


export const listMyQuotes = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUOTE_LIST_MY_REQUEST
    })

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("access")}`
      }
    }

    const { data } = await axios.get(
      process.env.REACT_APP_API_ENDPOINT_URL + `/api/orders/`,
      config
    )

    dispatch({
      type: QUOTE_LIST_MY_SUCCESS,
      payload: data
    })
    localStorage.setItem('listMyQuotes', JSON.stringify(data))

  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: QUOTE_LIST_MY_FAIL,
      payload: errorMessage
    })
  }
}



//Save data generated by the importer
export const saveData = (order) => async (dispatch) => {

  try {
    const result = await axios.get(process.env.REACT_APP_API_ENDPOINT_URL + "/auth/users/me/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    });

    if (result.data?.success !== true) {
      dispatch({
        type: LOGOUT,
      });
      dispatch({
        type: USER_DETAILS_RESET,
      });
      return;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      }
    }

    const body = JSON.stringify(order);

    const orderData = await axios.put(
      process.env.REACT_APP_API_ENDPOINT_URL + `/api/orders/${order.orderId}`,
      body,
      config,
    );

    dispatch({
      type: ORDER_SAVE_DATA,
      payload: orderData,
    });

    if(orderData.status === 200)
      orderData.success = true;

    return orderData;

  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: QUOTE_LIST_FAIL,
      payload: errorMessage
    });
    return errorMessage;
  }
}



export const listQuotes = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUOTE_LIST_REQUEST
    })

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("access")}`
      }
    }

    const { data } = await axios.get(
      process.env.REACT_APP_API_ENDPOINT_URL + `/api/quotes`,
      config
    )

    dispatch({
      type: QUOTE_LIST_SUCCESS,
      payload: data
    })


  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: QUOTE_LIST_FAIL,
      payload: errorMessage
    });
  }
}

export const listCylinders = () => async (dispatch) => {
  try {

    dispatch({ type: CYLINDERS_LIST_REQUEST })

    const { data } = await axios.get(process.env.REACT_APP_API_ENDPOINT_URL + '/api/Cylinders')

    dispatch({
      type: CYLINDERS_LIST_SUCCESS,
      payload: data
    })

  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: CYLINDERS_LIST_FAIL,
      payload: errorMessage
    })
  }
}

export const listCfdis = () => async (dispatch) => {
  try {

    dispatch({ type: CFDI_LIST_REQUEST })

    const { data } = await axios.get(process.env.REACT_APP_API_ENDPOINT_URL + '/api/CFDIUsages')

    dispatch({
      type: CFDI_LIST_SUCCESS,
      payload: data
    })

  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: CFDI_LIST_FAIL,
      payload: errorMessage
    })
  }
}

export const listDoors = () => async (dispatch) => {
  try {

    dispatch({ type: DOOR_LIST_REQUEST })

    const { data } = await axios.get(process.env.REACT_APP_API_ENDPOINT_URL + '/api/Doors')

    dispatch({
      type: DOOR_LIST_SUCCESS,
      payload: data
    })

  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: DOOR_LIST_FAIL,
      payload: errorMessage
    })
  }
}

export const listMileages = () => async (dispatch) => {
  try {

    dispatch({ type: MILEAGE_LIST_REQUEST })

    const { data } = await axios.get(process.env.REACT_APP_API_ENDPOINT_URL + '/api/Milages')

    dispatch({
      type: MILEAGE_LIST_SUCCESS,
      payload: data
    })

  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: MILEAGE_LIST_FAIL,
      payload: errorMessage
    })
  }
}

export const listMotors = () => async (dispatch) => {
  try {

    dispatch({ type: MOTORS_LIST_REQUEST })

    const { data } = await axios.get(process.env.REACT_APP_API_ENDPOINT_URL + '/api/Motors')

    dispatch({
      type: MOTORS_LIST_SUCCESS,
      payload: data
    })

  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: MOTORS_LIST_FAIL,
      payload: errorMessage
    })
  }
}

export const listVehicleTypes = () => async (dispatch) => {
  try {

    dispatch({ type: VEHICLETYPES_LIST_REQUEST })

    const { data } = await axios.get(process.env.REACT_APP_API_ENDPOINT_URL + '/api/VehicleTypes')

    dispatch({
      type: VEHICLETYPES_LIST_SUCCESS,
      payload: data
    })

  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: VEHICLETYPES_LIST_FAIL,
      payload: errorMessage
    })
  }
}

export const listStates = () => async (dispatch) => {
  try {

    dispatch({ type: STATES_LIST_REQUEST })

    const { data } = await axios.get(process.env.REACT_APP_API_ENDPOINT_URL + '/api/States')

    dispatch({
      type: STATES_LIST_SUCCESS,
      payload: data
    })

  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: STATES_LIST_FAIL,
      payload: errorMessage
    })
  }
}

export const listStateOffices = (id) => async (dispatch) => {
  try {

    dispatch({ type: STATEOFFICES_LIST_REQUEST })

    const { data } = await axios.get(process.env.REACT_APP_API_ENDPOINT_URL + `/api/stateoffices/bystateid/${id}`)

    dispatch({
      type: STATEOFFICES_LIST_SUCCESS,
      payload: data
    });
  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: STATEOFFICES_LIST_FAIL,
      payload: errorMessage
    })
  }
}

export const listCityCustoms = (id) => async (dispatch) => {
  try {

    dispatch({ type: CITYCUSTOMS_LIST_REQUEST })

    const { data } = await axios.get(process.env.REACT_APP_API_ENDPOINT_URL + `/api/CityCustoms`);

    dispatch({
      type: CITYCUSTOMS_LIST_SUCCESS,
      payload: data
    });
  } catch (error) {
    const errorMessage = error.response?.data?.errors && error.response?.data?.errors.length > 0
      ? error.response.data.errors[0].message
      : error.message;
    dispatch({
      type: CITYCUSTOMS_LIST_FAIL,
      payload: errorMessage
    })
  }
}