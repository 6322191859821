import React, { useEffect } from "react";

import { connect } from "react-redux";
import { checkAuthentication, loadUser } from "../store/actions/actions";

import { ToastContainer, Slide } from "react-toastify";

// higher order react component
const LayoutApp = ({ checkAuthentication, loadUser, children }) => {
  useEffect(() => {
    checkAuthentication();
    loadUser();
  });

  return (
    <>
      <ToastContainer transition={Slide}
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      icon={false} />
      {children}
    </>
  );
};

// Connect react component with redux store
export const Layout = connect(null, { checkAuthentication, loadUser })(
  LayoutApp
);
