import { Dashboard } from "../views/Dashboard.js";
import { QuoteScreen } from "../views/QuoteScreen.js";
import { ProfileScreen } from "../views/ProfileScreen.js";
import { Pay } from "../views/PayScreen.js";
import { Success } from "../components/MexPago/Success";

const dashboardRoutes = [
  
  {
    path: "/dashboard",
    name: "General",
    icon: "nc-icon nc-chart-bar-32",
    component: Dashboard,
    layout: "/home",
  },
  {
    redirect:true,
    invisible: true,
    path: "/quote/pay/:id",
    name: "Pago",
    icon: "nc-icon nc-paper-2",
    component: Pay,
    layout: "/home",
  },
  {
    redirect:true,
    invisible: true,
    path: "/quote/success",
    name: "Aprobación",
    icon: "nc-icon nc-paper-2",
    component: Success,
    layout: "/home",
  },
  {
    redirect:true,
    invisible: true,
    path: "/quote/:id",
    name: "Formulario",
    icon: "nc-icon nc-paper-2",
    component: QuoteScreen,
    layout: "/home",
  },
  {
    path: "/profile",
    name: "Perfil",
    icon: "nc-icon nc-circle-09",
    component: ProfileScreen,
    layout: "/home",
  }
];

export default dashboardRoutes;


export const Routes = {
  Home: { path: "/dashboard" },
  Dashboard: { path: "/" },
  Dropdown: { path: "/dropdown" },
  Loginpage: { path: "/login" },
  signup: { path: "/signup" },
  resetpassword: { path: "/reset-password" },
  resetpasswordconfirm: { path: "/password/reset/:uid/:token" },
  activation: { path: "/activate/:uid/:token" },
};
