//AUTH CONSTANTS
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILED = 'SIGNUP_FAILED'

export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS'
export const ACTIVATION_FAILED = 'ACTIVATION_FAILED'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'

export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS'
export const LOAD_USER_FAILED = 'LOAD_USER_FAILED'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS'
export const AUTHENTICATED_FAILED = 'AUTHENTICATED_FAILED'

export const LOGOUT = 'LOGOUT'

export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_FAILED = 'PASSWORD_RESET_FAILED'

export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS'
export const PASSWORD_RESET_CONFIRM_FAILED = 'PASSWORD_RESET_CONFIRM_FAILED'


//CARS CONSTANTS
export const BRANDS_LIST_REQUEST = 'BRANDS_LIST_REQUEST'
export const BRANDS_LIST_SUCCESS = 'BRANDS_LIST_SUCCESS'
export const BRANDS_LIST_FAIL = 'BRANDS_LIST_FAIL'

export const MODELS_LIST_REQUEST = 'MODELS_LIST_REQUEST'
export const MODELS_LIST_SUCCESS = 'MODELS_LIST_SUCCESS'
export const MODELS_LIST_FAIL = 'MODELS_LIST_FAIL'

export const YEARS_LIST_REQUEST = 'YEARS_LIST_REQUEST'
export const YEARS_LIST_SUCCESS = 'YEARS_LIST_SUCCESS'
export const YEARS_LIST_FAIL = 'YEARS_LIST_FAIL'

export const PRICES_LIST_REQUEST = 'PRICES_LIST_REQUEST'
export const PRICES_LIST_SUCCESS = 'PRICES_LIST_SUCCESS'
export const PRICES_LIST_FAIL = 'PRICES_LIST_FAIL'

export const PRICES_CONST_REQUEST = 'PRICES_CONST_REQUEST'
export const PRICES_CONST_SUCCESS = 'PRICES_CONST_SUCCESS'
export const PRICES_CONST_FAIL = 'PRICES_CONST_FAIL'



//1ERA ETAPA DE COTIZACIÓN - QUOTE FIRST PART
export const QUOTE_CREATE_REQUEST = 'QUOTE_CREATE_REQUEST'
export const QUOTE_CREATE_SUCCESS = 'QUOTE_CREATE_SUCCESS'
export const QUOTE_CREATE_FAIL = 'QUOTE_CREATE_FAIL'

export const QUOTE_CREATE_RESET = 'QUOTE_CREATE_RESET'


//2DA ETAPA DE COTIZACIÓN - QUOTE SECOND PART
export const QUOTE_DETAILS_REQUEST = 'QUOTE_DETAILS_REQUEST'
export const QUOTE_DETAILS_SUCCESS = 'QUOTE_DETAILS_SUCCESS'
export const QUOTE_DETAILS_FAIL = 'QUOTE_DETAILS_FAIL'

export const QUOTE_LIST_MY_REQUEST = 'QUOTE_LIST_MY_REQUEST'
export const QUOTE_LIST_MY_SUCCESS = 'QUOTE_LIST_MY_SUCCESS'
export const QUOTE_LIST_MY_FAIL = 'QUOTE_LIST_MY_FAIL'
export const QUOTE_LIST_MY_RESET = 'QUOTE_LIST_MY_RESET'

export const QUOTE_LIST_REQUEST = 'QUOTE_LIST_REQUEST'
export const QUOTE_LIST_SUCCESS = 'QUOTE_LIST_SUCCESS'
export const QUOTE_LIST_FAIL = 'QUOTE_LIST_FAIL'

export const QUOTE_LISTALL_REQUEST = 'QUOTE_LISTALL_REQUEST'
export const QUOTE_LISTALL_SUCCESS = 'QUOTE_LISTALL_SUCCESS'
export const QUOTE_LISTALL_FAIL = 'QUOTE_LISTALL_FAIL'


export const ORDER_SAVE_DATA = 'ORDER_SAVE_DATA'
export const ORDER_CLEAR_ITEMS = 'ORDER_CLEAR_ITEMS'

export const ORDER_PAID_REQUEST = 'ORDER_PAID_REQUEST'
export const ORDER_PAID_SUCCESS = 'ORDER_PAID_SUCCESS'
export const ORDER_PAID_FAIL = 'ORDER_PAID_FAIL'

export const CYLINDERS_LIST_REQUEST = 'CYLINDERS_LIST_REQUEST';
export const CYLINDERS_LIST_SUCCESS = 'CYLINDERS_LIST_SUCCESS';
export const CYLINDERS_LIST_FAIL = 'CYLINDERS_LIST_FAIL';

export const CFDI_LIST_REQUEST = 'CFDI_LIST_REQUEST';
export const CFDI_LIST_SUCCESS = 'CFDI_LIST_SUCCESS';
export const CFDI_LIST_FAIL = 'CFDI_LIST_FAIL';

export const DOOR_LIST_REQUEST = 'DOOR_LIST_REQUEST';
export const DOOR_LIST_SUCCESS = 'DOOR_LIST_SUCCESS';
export const DOOR_LIST_FAIL = 'DOOR_LIST_FAIL';

export const MILEAGE_LIST_REQUEST = 'MILEAGE_LIST_REQUEST';
export const MILEAGE_LIST_SUCCESS = 'MILEAGE_LIST_SUCCESS';
export const MILEAGE_LIST_FAIL = 'MILEAGE_LIST_FAIL';

export const MOTORS_LIST_REQUEST = 'MOTORS_LIST_REQUEST';
export const MOTORS_LIST_SUCCESS = 'MOTORS_LIST_SUCCESS';
export const MOTORS_LIST_FAIL = 'MOTORS_LIST_FAIL';

export const VEHICLETYPES_LIST_REQUEST = 'VEHICLETYPES_LIST_REQUEST';
export const VEHICLETYPES_LIST_SUCCESS = 'VEHICLETYPES_LIST_SUCCESS';
export const VEHICLETYPES_LIST_FAIL = 'VEHICLETYPES_LIST_FAIL';

export const STATES_LIST_REQUEST = 'STATES_LIST_REQUEST';
export const STATES_LIST_SUCCESS = 'STATES_LIST_SUCCESS';
export const STATES_LIST_FAIL = 'STATES_LIST_FAIL';

export const STATEOFFICES_LIST_REQUEST = 'STATEOFFICES_LIST_REQUEST';
export const STATEOFFICES_LIST_SUCCESS = 'STATEOFFICES_LIST_SUCCESS';
export const STATEOFFICES_LIST_FAIL = 'STATEOFFICES_LIST_FAIL';

export const CITYCUSTOMS_LIST_REQUEST = 'CITYCUSTOMS_LIST_REQUEST';
export const CITYCUSTOMS_LIST_SUCCESS = 'CITYCUSTOMS_LIST_SUCCESS';
export const CITYCUSTOMS_LIST_FAIL = 'CITYCUSTOMS_LIST_FAIL';