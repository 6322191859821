import React, { useEffect } from 'react'
// Router redirection
import { Redirect } from "react-router-dom";

// redux connect with react
import { connect } from "react-redux";

// Bootstrap
import {
  Container,
  Row,
  Col,
  Card,
  Form
} from "react-bootstrap";

import { useDispatch } from 'react-redux';

import MexPago from "../components/MexPago/MexPago.js";
import { getQuoteDetails } from "../store/actions/actions";

const PayApp = ({ match, isAuthenticated }) => {

  const quoteId = match.params.id

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getQuoteDetails(quoteId));
  }, [dispatch, quoteId]);

  return (
    <>
      {isAuthenticated ? (


        <div className="d-flex align-items-center  py-3">
          <Container >
            <Row className="align-items-center justify-content-center ">
              <Col lg={5}>
                <Card>
                  <Card.Body className="p-4">
                    <div className="text-center">
                      <h2 className="text-dark mb-3">Proceso de pago</h2>
                    </div>
                    <Form>
                      <Form.Group className="text-center">
                        <MexPago />
                      </Form.Group>
                    </Form>
                  </Card.Body>
                  <Card.Footer>
                    <hr></hr>
                    <div className="stats">
                      <p className="text-gray x-small mb-3">
                        TIPO DE CAMBIO.- El precio del servicio prestado deberá ser pagado en dólares,
                        o su equivalente en pesos mexicanos, al tipo de cambio de la fecha en la que se
                        realice dicho pago, publicado en el Diario Oficial de la Federación.
                      </p>

                      <p className="text-gray x-small mb-3">
                        ACEPTACIÓN DE LAS CONDICIONES DEL CONTRATO.- Al realizar el pago correspondiente del
                        servicio contratado, EL CLIENTE acepta todas y cada una las condiciones contenidas en el
                        presente contrato.
                      </p>

                      <p className="text-gray x-small">
                        DOCUMENTACIÓN.- El CLIENTE  manifiesta bajo protesta de decir verdad que los documentos proporcionados
                        al PRESTADOR DEL SERVICIO, para la realización del servicio contratado, son veraces y auténticas
                        copias de sus originales, sin que los mismos hayan sido alterados o modificados de ninguna forma,
                        haciéndose plenamente responsables de lo anterior, y liberando al PRESTADOR DEL SERVICIO de cualquier
                        responsabilidad derivada de dichos documentos.
                      </p>

                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

      ) : (
        <Redirect to="/login" />
      )}
    </>
  );
};

// Get Data from redux store
const mapToStateProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

// Connect react component with redux store
export const Pay = connect(mapToStateProps)(PayApp);
