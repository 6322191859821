import {
    ACTIVATION_SUCCESS,
    ACTIVATION_FAILED,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAILED,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAILED,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAILED,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAILED,
    SIGNUP_SUCCESS,
    SIGNUP_FAILED,

    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DETAILS_RESET,

    //CARS ACTIONS
    BRANDS_LIST_REQUEST,
    BRANDS_LIST_SUCCESS,
    BRANDS_LIST_FAIL,

    MODELS_LIST_REQUEST,
    MODELS_LIST_SUCCESS,
    MODELS_LIST_FAIL,

    YEARS_LIST_REQUEST,
    YEARS_LIST_SUCCESS,
    YEARS_LIST_FAIL,

    PRICES_LIST_REQUEST,
    PRICES_LIST_SUCCESS,
    PRICES_LIST_FAIL,

    PRICES_CONST_REQUEST,
    PRICES_CONST_SUCCESS,
    PRICES_CONST_FAIL,

    //ORDER ITEMS
    ORDER_SAVE_DATA,
    ORDER_CLEAR_ITEMS,

    QUOTE_CREATE_REQUEST,
    QUOTE_CREATE_SUCCESS,
    QUOTE_CREATE_FAIL,
    QUOTE_CREATE_RESET,

    QUOTE_DETAILS_REQUEST,
    QUOTE_DETAILS_SUCCESS,
    QUOTE_DETAILS_FAIL,

    QUOTE_LIST_MY_REQUEST,
    QUOTE_LIST_MY_SUCCESS,
    QUOTE_LIST_MY_FAIL,
    QUOTE_LIST_MY_RESET,

    ORDER_PAID_REQUEST,
    ORDER_PAID_SUCCESS,
    ORDER_PAID_FAIL,

    CYLINDERS_LIST_REQUEST,
    CYLINDERS_LIST_SUCCESS,
    CYLINDERS_LIST_FAIL,

    CFDI_LIST_REQUEST,
    CFDI_LIST_SUCCESS,
    CFDI_LIST_FAIL,

    DOOR_LIST_REQUEST,
    DOOR_LIST_SUCCESS,
    DOOR_LIST_FAIL,

    MILEAGE_LIST_REQUEST,
    MILEAGE_LIST_SUCCESS,
    MILEAGE_LIST_FAIL,

    MOTORS_LIST_REQUEST,
    MOTORS_LIST_SUCCESS,
    MOTORS_LIST_FAIL,
  
    VEHICLETYPES_LIST_REQUEST,
    VEHICLETYPES_LIST_SUCCESS,
    VEHICLETYPES_LIST_FAIL,

    STATES_LIST_REQUEST,
    STATES_LIST_SUCCESS,
    STATES_LIST_FAIL,

    STATEOFFICES_LIST_REQUEST,
    STATEOFFICES_LIST_SUCCESS,
    STATEOFFICES_LIST_FAIL,

    CITYCUSTOMS_LIST_REQUEST,
    CITYCUSTOMS_LIST_SUCCESS,
    CITYCUSTOMS_LIST_FAIL,

} from "../actions/types";

const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : []

const quoteIdsFromStorage = localStorage.getItem('listMyQuotes') ?
    JSON.parse(localStorage.getItem('listMyQuotes')) : []

const initialState = { 
  access: localStorage.getItem("access"),
  refresh: localStorage.getItem("refresh"),
  isAuthenticated: null,
  user: null,
  loading: true,
  userInfo: userInfoFromStorage,
  listMyQuotes: quoteIdsFromStorage
};

export const auth = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      localStorage.setItem("access", payload.tokenString);
      localStorage.setItem("refresh", payload.refreshToken);
      return {
        ...state,
        isAuthenticated: true,
        access: payload.tokenString,
        refresh: payload.refreshToken,
        loading: false,
      };
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        user: payload,
        loading: false,
      };

    case SIGNUP_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };
    case AUTHENTICATED_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
      };
    case AUTHENTICATED_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };
    case SIGNUP_FAILED:
    case LOGIN_FAILED:
    case LOGOUT:
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      localStorage.removeItem("listMyQuotes");
      localStorage.removeItem("userInfo");
      return {
        ...state,
        access: null,
        refresh: null,
        isAuthenticated: false,
        user: null,
        loading: false,
      };
    case LOAD_USER_FAILED:
      return {
        ...state,
        user: null,
        loading: false,
      };
    case PASSWORD_RESET_SUCCESS:
    case PASSWORD_RESET_FAILED:
    case PASSWORD_RESET_CONFIRM_SUCCESS:
    case PASSWORD_RESET_CONFIRM_FAILED:
    case ACTIVATION_SUCCESS:
    case ACTIVATION_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};


export const myUserDetailsReducer = (state = {}, action) => {
  switch (action.type) {
      case USER_DETAILS_REQUEST:
          return { ...state, loading: true }

      case USER_DETAILS_SUCCESS:
          return { loading: false, userLogin: action.payload }

      case USER_DETAILS_FAIL:
          return { loading: false, error: action.payload }

      case USER_DETAILS_RESET:
          return {}


      default:
          return state
  }
}

//Car brands, models, year and prices options
export const brandsList = (state = { brands: [] }, action) => {
  switch (action.type) {
      case BRANDS_LIST_REQUEST:
          return { loading: true, brands: [] }

      case BRANDS_LIST_SUCCESS:
          return {
              loading: false,
              brands: action.payload,
              
          }

      case BRANDS_LIST_FAIL:
          return { loading: false, error: action.payload }

      default:
          return state
  }
};


export const modelsList = (state = { models: [] }, action) => {
  switch (action.type) {
      case MODELS_LIST_REQUEST:
          return { loading: true, models: [] }

      case MODELS_LIST_SUCCESS:
          return { 
              loading: false,
              models: action.payload,
              
          }

      case MODELS_LIST_FAIL:
          return { loading: false, error: action.payload }

      default:
          return state
  }
};


export const yearsList = (state = { years: [] }, action) => {
  switch (action.type) {
      case YEARS_LIST_REQUEST:
          return { loading: true, years: [] }

      case YEARS_LIST_SUCCESS:
          return {
              loading: false,
              years: action.payload,
              
          }

      case YEARS_LIST_FAIL:
          return { loading: false, error: action.payload }

      default:
          return state
  }
};


export const pricesList = (state = { prices: [] }, action) => {
  switch (action.type) {
      case PRICES_LIST_REQUEST:
          return { loading: true, prices: [] }

      case PRICES_LIST_SUCCESS:
          return { 
              loading: false,
              prices: action.payload,
              
          }

      case PRICES_LIST_FAIL:
          return { loading: false, error: action.payload }

      default:
          return state
  }
};

export const constPricesList = (state = { cPrices: [] }, action) => {
  switch (action.type) {
      case PRICES_CONST_REQUEST:
          return { loading: true, cPrices: [] }

      case PRICES_CONST_SUCCESS:
          return { 
              loading: false,
              cPrices: action.payload,
              
          }

      case PRICES_CONST_FAIL:
          return { loading: false, error: action.payload }

      default:
          return state
  }
};

//ORDER ITEMS
export const dataReducer = (state = { orderItems: {} }, action) => {
  switch (action.type) {
      case ORDER_SAVE_DATA:
          return {
              ...state,
              orderItems: action.payload
          }

      case ORDER_CLEAR_ITEMS:
          return {
              ...state,
              orderItems: {}
          }

      default:
          return state
  }
}

export const quoteCreateReducer = (state = {}, action) => {
  switch (action.type) {
      case QUOTE_CREATE_REQUEST:
          return {
              loading: true
          }

      case QUOTE_CREATE_SUCCESS:
          return {
              loading: false,
              success: true,
              orderItem: action.payload
          }

      case QUOTE_CREATE_FAIL:
          return {
              loading: false,
              error: action.payload
          }

      case QUOTE_CREATE_RESET:
          return {}


      default:
          return state
  }
}

export const quoteDetailsReducer = (state = { loading: true, data: []  }, action) => {
  switch (action.type) {
      case QUOTE_DETAILS_REQUEST:
          return {
              ...state,
              loading: true
          }

      case QUOTE_DETAILS_SUCCESS:
          return {
              loading: false,
              data: action.payload
          }

      case QUOTE_DETAILS_FAIL:
          return {
              loading: false,
              error: action.payload
          }


      default:
          return state
  }
}



export const quoteListMyReducer = (state = { quotes: [] }, action) => {
  switch (action.type) {
      case QUOTE_LIST_MY_REQUEST:
          return {
              loading: true
          }

      case QUOTE_LIST_MY_SUCCESS:
          return {
              loading: false,
              quotes: action.payload
          }

      case QUOTE_LIST_MY_FAIL:
          return {
              loading: false,
              error: action.payload
          }

      case QUOTE_LIST_MY_RESET:
          return {
              quotes: []
          }

      default:
          return state
  }
}

export const payOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_PAID_REQUEST:
            return {
                loading: true
            }
  
        case ORDER_PAID_SUCCESS:
            return {
                loading: false,
                success: true,
                orderItem: action.payload
            }
  
        case ORDER_PAID_FAIL:
            return {
                loading: false,
                error: action.payload
            }  
  
        default:
            return state
    }
  }

  export const cylinderList = (state = { cylinders: [] }, action) => {
    switch (action.type) {
        case CYLINDERS_LIST_REQUEST:
            return { loading: true, brands: [] }
        case CYLINDERS_LIST_SUCCESS:
            return {
                loading: false,
                cylinders: action.payload,
                
            }
        case CYLINDERS_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
  };

  export const cfdiList = (state = { cfdis: [] }, action) => {
    switch (action.type) {
        case CFDI_LIST_REQUEST:
            return { loading: true, brands: [] }
        case CFDI_LIST_SUCCESS:
            return {
                loading: false,
                cfdis: action.payload,
            }
        case CFDI_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
  };

  export const doorList = (state = { doors: [] }, action) => {
    switch (action.type) {
        case DOOR_LIST_REQUEST:
            return { loading: true, brands: [] }
        case DOOR_LIST_SUCCESS:
            return {
                loading: false,
                doors: action.payload,
            }
        case DOOR_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
  };

  export const mileageList = (state = { mileages: [] }, action) => {
    switch (action.type) {
        case MILEAGE_LIST_REQUEST:
            return { loading: true, brands: [] }
        case MILEAGE_LIST_SUCCESS:
            return {
                loading: false,
                mileages: action.payload,
            }
        case MILEAGE_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
  };

  export const motorsList = (state = { motors: [] }, action) => {
    switch (action.type) {
        case MOTORS_LIST_REQUEST:
            return { loading: true, brands: [] }
        case MOTORS_LIST_SUCCESS:
            return {
                loading: false,
                motors: action.payload,
            }
        case MOTORS_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
  };

  export const vehicleTypesList = (state = { vehicleTypes: [] }, action) => {
    switch (action.type) {
        case VEHICLETYPES_LIST_REQUEST:
            return { loading: true, brands: [] }
        case VEHICLETYPES_LIST_SUCCESS:
            return {
                loading: false,
                vehicleTypes: action.payload,
            }
        case VEHICLETYPES_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
  };

  export const statesList = (state = { states: [] }, action) => {
    switch (action.type) {
        case STATES_LIST_REQUEST:
            return { loading: true, states: [] }
        case STATES_LIST_SUCCESS:
            return {
                loading: false,
                states: action.payload,
            }
        case STATES_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
  };

  export const stateOfficesList = (state = { stateOffices: [] }, action) => {
    switch (action.type) {
        case STATEOFFICES_LIST_REQUEST:
            return { loading: true, stateOffices: [] }
        case STATEOFFICES_LIST_SUCCESS:
            return {
                loading: false,
                stateOffices: action.payload,
            }
        case STATEOFFICES_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
  };

  export const cityCustomsList = (state = { cityCustoms: [] }, action) => {
    switch (action.type) {
        case CITYCUSTOMS_LIST_REQUEST:
            return { loading: true, cityCustoms: [] }
        case CITYCUSTOMS_LIST_SUCCESS:
            return {
                loading: false,
                cityCustoms: action.payload,
            }
        case CITYCUSTOMS_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
  };