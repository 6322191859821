import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Loader } from '../components/Loader/Loader'

// Router
import { Redirect } from "react-router-dom";

// redux connect with react
import { connect } from "react-redux";

import {
  Form, FormControl,
  Container, Row,
  Col, Card,
  Button, Spinner,
  ListGroup, Image, FloatingLabel
} from 'react-bootstrap'


import InputGroup from 'react-bootstrap/InputGroup'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import ImageLoader from "../components/Loader/ImageLoader";
import { getQuoteDetails, saveData, listCfdis, listDoors, listMileages, listMotors, listStates, listStateOffices } from "../store/actions/actions";

import { toast } from "react-toastify";
import * as css from '../styles/app.css'

import Steps, { Step } from "rmc-steps"
import * as steps from "rmc-steps/assets/index.css"

export const QuoteInfoApp = ({ isAuthenticated, match }) => {

  let history = useHistory();
  const orderId = match.params.id

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listCfdis());
    dispatch(listDoors());
    dispatch(listMileages());
    dispatch(listMotors());
    dispatch(listStates());
  }, [dispatch])

  useEffect(() => {
    dispatch(getQuoteDetails(orderId));
  }, [dispatch, orderId]);

  const quoteDetails = useSelector(state => state.quoteDetails)
  const { data, error, loading } = quoteDetails

  const auth = useSelector(state => state.auth);
  const { user } = auth;

  const userLogin = useSelector(state => state.auth);
  const { userInfo } = userLogin;

  const cfdiList = useSelector(state => state.cfdiList);
  const { cfdis } = cfdiList;

  const doorList = useSelector(state => state.doorList);
  const { doors } = doorList;

  const mileageList = useSelector(state => state.mileageList);
  const { mileages } = mileageList;

  const motorsList = useSelector(state => state.motorsList);
  const { motors } = motorsList;

  const statesList = useSelector(state => state.statesList);
  const { states } = statesList;

  const stateOfficesList = useSelector(state => state.stateOfficesList);
  const { stateOffices } = stateOfficesList;

  useEffect(() => {
    if (data) {
      setName(data.firstName);
      setApPaterno(data.lastName);
      setApMaterno(data.mothersName);
      setPhone(data.phone);
      setPhoneCompany(data.phoneCompany);
      setCalle(data.street);
      setNroInterior(data.internalNumber);
      setNroExterior(data.externalNumber);
      setColonia(data.locality);
      setMunicipio(data.city);
      setEstado(data.state);
      setCP(data.postalCode);
      setCondicion(data.personType);
      setRFC(data.rfc);
      setCURP(data.curp);
      setAduana(data.customs);
      setCFDI(data.cfdiUsageId);
      setFraccionVehiculo(data.fraction);
      setMotor(data.motorId);
      setNroPuertas(data.doorId);
      setColor(data.color);
      setKm(data.mileageId);

      setFrontINE(data.front_INE);
      setCDMIC(data.proof_of_address);
      setFrontTitle(data.front_title);
      setBackTitle(data.back_title);
      setVIN(data.VIN_image);
      setFrontVehicle(data.vehicle_front_image);
      setBackVehicle(data.vehicle_back_image);
      setSide1Vehicle(data.vehicle_side_image);
      setSide2Vehicle(data.vehicle_side2_image);
      setSerialNumberImage(data.serial_number_image);
      setMile(data.carmiles_image);
      setLicense(data.license_image);
      setContract(data.contract);
      if (data.contract)
        setContractValid(true);

      setCalleFiscal(data.fiscalStreet);
      setNroInteriorFiscal(data.fiscalInternalNumber);
      setNroExteriorFiscal(data.fiscalExternalNumber);
      setColoniaFiscal(data.fiscalLocality);
      setMunicipioFiscal(data.fiscalCity);
      setEstadoFiscal(data.fiscalState);
      setCPFiscal(data.fiscalPostalCode);

      setStep(data.status);

      if (data.personType &&
        data.firstName &&
        data.lastName &&
        data.mothersName &&
        data.fiscalStreet &&
        data.fiscalExternalNumber &&
        data.fiscalLocality &&
        data.fiscalCity &&
        data.fiscalState &&
        data.fiscalPostalCode &&
        data.rfc &&
        data.phone &&
        data.serial_number &&
        data.brand?.name &&
        data.color &&
        data.model?.name &&
        data.mileage?.name &&
        data.motor?.name
      ) {
        setInformationSaved(true);
      }
    }
  }, [dispatch, data]);



  //upload states
  const [uploading1, setUploading1] = useState(false)
  const [uploading3, setUploading3] = useState(false)
  const [uploading4, setUploading4] = useState(false)
  const [uploading5, setUploading5] = useState(false)
  const [uploading6, setUploading6] = useState(false)
  const [uploading7, setUploading7] = useState(false)
  const [uploading8, setUploading8] = useState(false)
  const [uploading9, setUploading9] = useState(false)
  const [uploading10, setUploading10] = useState(false)
  const [uploading11, setUploading11] = useState(false)
  const [uploading12, setUploading12] = useState(false)
  const [uploading13, setUploading13] = useState(false)
  const [uploading14, setUploading14] = useState(false)
  const [requestSent, setRequestSent] = useState(false);


  const [name, setName] = useState("")
  const [apPaterno, setApPaterno] = useState("")
  const [apMaterno, setApMaterno] = useState("")
  const [phone, setPhone] = useState("")
  const [phoneCompany, setPhoneCompany] = useState("")
  const [calle, setCalle] = useState("")
  const [nroInterior, setNroInterior] = useState("")
  const [nroExterior, setNroExterior] = useState("")
  const [colonia, setColonia] = useState("")
  const [municipio, setMunicipio] = useState("")
  const [estado, setEstado] = useState("")
  const [cp, setCP] = useState("")
  const [CFDI, setCFDI] = useState("")
  const [condicion, setCondicion] = useState("")
  const [RFC, setRFC] = useState("")
  const [CURP, setCURP] = useState("")
  const [aduana, setAduana] = useState("")

  const [frontINE, setFrontINE] = useState("")
  const [CDMIC, setCDMIC] = useState("")

  const [fraccionVehiculo, setFraccionVehiculo] = useState("")
  const [motorId, setMotor] = useState("")
  const [nroPuertas, setNroPuertas] = useState("")
  const [color, setColor] = useState("")
  const [km, setKm] = useState("")
  const [frontTitle, setFrontTitle] = useState("")
  const [backTitle, setBackTitle] = useState("")
  const [VIN, setVIN] = useState("")
  const [frontVehicle, setFrontVehicle] = useState("")
  const [backVehicle, setBackVehicle] = useState("")
  const [side1Vehicle, setSide1Vehicle] = useState("")
  const [side2Vehicle, setSide2Vehicle] = useState("")
  const [serialNumberImage, setSerialNumberImage] = useState("")
  const [mile, setMile] = useState("")
  const [license, setLicense] = useState("")
  const [contract, setContract] = useState("")

  const [razonSocial, setRazonSocial] = useState("");

  const [fiscalAddress, setFiscalAddress] = useState("");

  const [calleFiscal, setCalleFiscal] = useState("")
  const [nroInteriorFiscal, setNroInteriorFiscal] = useState("")
  const [nroExteriorFiscal, setNroExteriorFiscal] = useState("")
  const [coloniaFiscal, setColoniaFiscal] = useState("")
  const [municipioFiscal, setMunicipioFiscal] = useState("")
  const [estadoFiscal, setEstadoFiscal] = useState("")
  const [cpFiscal, setCPFiscal] = useState("")
  const [informationSaved, setInformationSaved] = useState(false);
  const [contractValid, setContractValid] = useState(false);
  const [stateId, setStateId] = useState(null);

  const [step, setStep] = useState(0);

  const totalPrice = Number(data.price).toFixed(2)

  useEffect(() => {
    if (fiscalAddress === "SameFiscal") {
      setCalleFiscal(calle)
      setNroInteriorFiscal(nroInterior)
      setNroExteriorFiscal(nroExterior)
      setColoniaFiscal(colonia)
      setMunicipioFiscal(municipio)
      setEstadoFiscal(estado)
      setCPFiscal(cp)
    }
  }, [fiscalAddress, calle, nroInterior, nroExterior, colonia, municipio, estado, cp]);

  useEffect(() => {
    //fetch models when a brand is selected
    if (motorId) {
      var motor = motors?.find(x => x.motorId === motorId);
      if (motor) {
        setFraccionVehiculo(motor.fraction);
      }
    }
  }, [dispatch, motorId])

  useEffect(() => {
    //fetch models when a brand is selected
    if (stateId) {
      dispatch(listStateOffices(stateId));
    }
  }, [dispatch, stateId])
  // if (!user) {
  //   history.push('/login')
  // }

  const removeImage = (type) => {
    switch (type) {
      case "frontINE":
        setFrontINE(null);
        break;
      case "cdmic":
        setCDMIC(null)
        break
      case "frontTitle":
        setFrontTitle(null);
        break;
      case "backTitle":
        setBackTitle(null);
        break;
      case "vin":
        setVIN(null);
        break;
      case "frontVehicle":
        setFrontVehicle(null)
        break;
      case "backVehicle":
        setBackVehicle(null)
        break;
      case "sideOne":
        setSide1Vehicle(null);
        break;
      case "sideTwo":
        setSide2Vehicle(null);
        break;
      case "serialNumber":
        setSerialNumberImage(null);
        break;
      case "mile":
        setMile(null);
        break;
      case "license":
        setLicense(null);
        break;
      case "contract":
        setContract(null);
        setContractValid(false);
        break;
    }
  }

  //Upload file functions
  const frontIneHandler = async (e) => {
    const file = e.target.files[0]
    const formDataImage = new FormData()

    formDataImage.append('file', file)
    formDataImage.append('orderId', orderId)
    formDataImage.append('type', 'frontine')


    setUploading1(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }

      const { data } = await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + '/api/upload', formDataImage, config)
      if(data?.success)
      {
        setFrontINE(data.id);
      }
      else
      {
        toast.error(data?.message ?? "ocurrió un error al subir tu archivo", {
          hideProgressBar: true,
          autoClose: 4000,
        })
      }
      setUploading1(false);
      
    } catch (error) {
      toast.error(error.response.data?.message ?? "ocurrió un error al subir tu archivo", {
        hideProgressBar: true,
        autoClose: 4000
      })
      setUploading1(false);
    }
  }

  const cdmicHandler = async (e) => {
    const file = e.target.files[0]
    const formDataImage = new FormData()

    formDataImage.append('file', file)
    formDataImage.append('orderId', orderId)
    formDataImage.append('type', 'cdmic')


    setUploading3(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }

      const { data } = await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + '/api/upload', formDataImage, config)
      if(data?.success)
      {
        setCDMIC(data.id);
      }
      else
      {
        toast.error(data?.message ?? "ocurrió un error al subir tu archivo", {
          hideProgressBar: true,
          autoClose: 4000,
        })
      }
      setUploading3(false)
    } catch (error) {
      toast.error(error.response.data?.message ?? "ocurrió un error al subir tu archivo", {
        hideProgressBar: true,
        autoClose: 4000
      })
      setUploading3(false)
    }
  }


  const frontTitleHandler = async (e) => {
    const file = e.target.files[0]
    const formDataImage = new FormData()

    formDataImage.append('file', file)
    formDataImage.append('orderId', orderId)
    formDataImage.append('type', 'fronttitle')


    setUploading4(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }

      const { data } = await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + '/api/upload', formDataImage, config)

      if(data?.success)
      {
        setFrontTitle(data.id);
      }
      else
      {
        toast.error(data?.message ?? "ocurrió un error al subir tu archivo", {
          hideProgressBar: true,
          autoClose: 4000,
        })
      }
      setUploading4(false)

    } catch (error) {
      toast.error(error.response.data?.message ?? "ocurrió un error al subir tu archivo", {
        hideProgressBar: true,
        autoClose: 4000
      })
      setUploading4(false)
    }
  }


  const backTitleHandler = async (e) => {
    const file = e.target.files[0]
    const formDataImage = new FormData()

    formDataImage.append('file', file)
    formDataImage.append('orderId', orderId)
    formDataImage.append('type', 'backtitle')


    setUploading5(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }

      const { data } = await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + '/api/upload', formDataImage, config)

      if(data?.success)
      {
        setBackTitle(data.id);
      }
      else
      {
        toast.error(data?.message ?? "ocurrió un error al subir tu archivo", {
          hideProgressBar: true,
          autoClose: 4000,
        })
      }
      setUploading5(false)

    } catch (error) {
      toast.error(error.response.data?.message ?? "ocurrió un error al subir tu archivo", {
        hideProgressBar: true,
        autoClose: 4000
      })
      setUploading5(false)
    }
  }


  const vinHandler = async (e) => {
    const file = e.target.files[0]
    const formDataImage = new FormData()

    formDataImage.append('file', file)
    formDataImage.append('orderId', orderId)
    formDataImage.append('type', 'vin')


    setUploading6(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }

      const { data } = await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + '/api/upload', formDataImage, config)
      if(data?.success)
      {
        setVIN(data.id);
      }
      else
      {
        toast.error(data?.message ?? "ocurrió un error al subir tu archivo", {
          hideProgressBar: true,
          autoClose: 4000,
        })
      }
      setUploading6(false)

    } catch (error) {
      toast.error(error.response.data?.message ?? "ocurrió un error al subir tu archivo", {
        hideProgressBar: true,
        autoClose: 4000
      })
      setUploading6(false)
    }
  }


  const frontVehicleHandler = async (e) => {
    const file = e.target.files[0]
    const formDataImage = new FormData()

    formDataImage.append('file', file)
    formDataImage.append('orderId', orderId)
    formDataImage.append('type', 'frontvehicle')


    setUploading7(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }

      const { data } = await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + '/api/upload', formDataImage, config)
      if(data?.success)
      {
        setFrontVehicle(data.id);
      }
      else
      {
        toast.error(data?.message ?? "ocurrió un error al subir tu archivo", {
          hideProgressBar: true,
          autoClose: 4000,
        })
      }
      setUploading7(false)

    } catch (error) {
      toast.error(error.response.data?.message ?? "ocurrió un error al subir tu archivo", {
        hideProgressBar: true,
        autoClose: 4000
      })
      setUploading7(false)
    }
  }


  const backVehicleHandler = async (e) => {
    const file = e.target.files[0]
    const formDataImage = new FormData()

    formDataImage.append('file', file)
    formDataImage.append('orderId', orderId)
    formDataImage.append('type', 'backvehicle')


    setUploading8(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }

      const { data } = await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + '/api/upload', formDataImage, config)
      if(data?.success)
      {
        setBackVehicle(data.id);
      }
      else
      {
        toast.error(data?.message ?? "ocurrió un error al subir tu archivo", {
          hideProgressBar: true,
          autoClose: 4000,
        })
      }
      setUploading8(false)

    } catch (error) {
      toast.error(error.response.data?.message ?? "ocurrió un error al subir tu archivo", {
        hideProgressBar: true,
        autoClose: 4000
      })
      setUploading8(false)
    }
  }

  const side1Handler = async (e) => {
    const file = e.target.files[0]
    const formDataImage = new FormData()

    formDataImage.append('file', file)
    formDataImage.append('orderId', orderId)
    formDataImage.append('type', 'side1vehicle')


    setUploading9(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }

      const { data } = await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + '/api/upload', formDataImage, config)
      if(data?.success)
      {
        setSide1Vehicle(data.id);
      }
      else
      {
        toast.error(data?.message ?? "ocurrió un error al subir tu archivo", {
          hideProgressBar: true,
          autoClose: 4000,
        })
      }
      setUploading9(false)

    } catch (error) {
      toast.error(error.response.data?.message ?? "ocurrió un error al subir tu archivo", {
        hideProgressBar: true,
        autoClose: 4000
      })
      setUploading9(false)
    }
  }


  const side2Handler = async (e) => {
    const file = e.target.files[0]
    const formDataImage = new FormData()

    formDataImage.append('file', file)
    formDataImage.append('orderId', orderId)
    formDataImage.append('type', 'side2vehicle')


    setUploading10(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }

      const { data } = await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + '/api/upload', formDataImage, config)
      if(data?.success)
      {
        setSide2Vehicle(data.id);
      }
      else
      {
        toast.error(data?.message ?? "ocurrió un error al subir tu archivo", {
          hideProgressBar: true,
          autoClose: 4000,
        })
      }
      setUploading10(false)

    } catch (error) {
      toast.error(error.response.data?.message ?? "ocurrió un error al subir tu archivo", {
        hideProgressBar: true,
        autoClose: 4000
      })
      setUploading10(false)
    }
  }


  const SerialNumberHandler = async (e) => {
    const file = e.target.files[0]
    const formDataImage = new FormData()

    formDataImage.append('file', file)
    formDataImage.append('orderId', orderId)
    formDataImage.append('type', 'serialnumber')


    setUploading11(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }

      const { data } = await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + '/api/upload', formDataImage, config)
      if (data?.success)
      {
          setSerialNumberImage(data.id)
      }
      else
      {
        toast.error(data?.message ?? "ocurrió un error al subir tu archivo", {
          hideProgressBar: true,
          autoClose: 4000,
        })
      }
      setUploading11(false)

    } catch (error) {
      toast.error(error.response.data?.message ?? "ocurrió un error al subir tu archivo", {
        hideProgressBar: true,
        autoClose: 4000
      })
      setUploading11(false)
    }
  }


  const mileHandler = async (e) => {
    const file = e.target.files[0]
    const formDataImage = new FormData()

    formDataImage.append('file', file)
    formDataImage.append('orderId', orderId)
    formDataImage.append('type', 'miles')


    setUploading12(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }

      const { data } = await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + '/api/upload', formDataImage, config)
      if(data?.success)
      {
        setMile(data.id);
      }
      else
      {
        toast.error(data?.message ?? "ocurrió un error al subir tu archivo", {
          hideProgressBar: true,
          autoClose: 4000,
        })
      }
      setUploading12(false)

    } catch (error) {
      toast.error(error.response.data?.message ?? "ocurrió un error al subir tu archivo", {
        hideProgressBar: true,
        autoClose: 4000
      })
      setUploading12(false)
    }
  }

  const licenseHandler = async (e) => {
    const file = e.target.files[0]
    const formDataImage = new FormData()

    formDataImage.append('file', file)
    formDataImage.append('orderId', orderId)
    formDataImage.append('type', 'license')


    setUploading13(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }

      const { data } = await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + '/api/upload', formDataImage, config)
      if(data?.success)
      {
        setLicense(data.id);
      }
      else
      {
        toast.error(data?.message ?? "ocurrió un error al subir tu archivo", {
          hideProgressBar: true,
          autoClose: 4000,
        })
      }
      setUploading13(false)

    } catch (error) {
      toast.error(error.response.data?.message ?? "ocurrió un error al subir tu archivo", {
        hideProgressBar: true,
        autoClose: 4000
      })
      setUploading13(false)
    }
  }

  const contractHandler = async (e) => {
    const file = e.target.files[0]
    const formDataImage = new FormData()

    formDataImage.append('file', file)
    formDataImage.append('orderId', orderId)
    formDataImage.append('type', 'contract')


    setUploading14(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }

      const { data } = await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + '/api/upload', formDataImage, config)

      if(data?.success)
      {
        setContract(data.id)
        setContractValid(true);
      }
      else
      {
        toast.error(data?.message ?? "Ocurrió un error al subir tu archivo", {
          hideProgressBar: true,
          autoClose: 4000,
        })
      }
      setUploading14(false)

    } catch (error) {
      toast.error(error.response.data?.message ?? "ocurrió un error al subir tu archivo", {
        hideProgressBar: true,
        autoClose: 4000
      })
      setUploading14(false)
    }
  }

  const draftHandler = async (e) => {
    e.preventDefault();
    const form = e.currentTarget.form;
    if (form.checkValidity() === false) {
      form.reportValidity();
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    setRequestSent(true);
    const serial_number = data.serial_number;
    const email = data.user.email;
    const submitResponse = await dispatch(saveData(
      {
        orderId,
        name,
        apPaterno,
        apMaterno,
        phone,
        phoneCompany,
        calle,
        nroInterior,
        nroExterior,
        colonia,
        municipio,
        estado,
        cp,
        CFDI,
        condicion,
        totalPrice,
        RFC,
        CURP,
        aduana,
        fraccionVehiculo,
        motor: motorId,
        nroPuertas,
        color,
        km,
        serial_number,
        email,
        socialReason: razonSocial,
        fiscalStreet: calleFiscal,
        fiscalExternalNumber: nroExteriorFiscal,
        fiscalInternalNumber: nroInteriorFiscal,
        fiscalLocality: coloniaFiscal,
        fiscalCity: municipioFiscal,
        fiscalState: estadoFiscal,
        fiscalPostalCode: cpFiscal
      }
    ));
    setRequestSent(false);

    if (submitResponse.success) {
      toast.success("Información guardada", {
        hideProgressBar: true,
        autoClose: 6000,
      });
      setInformationSaved(true);
    }
    else {
      toast.error(submitResponse?.message ?? "Ocurrió un error al guardar tus datos")
    }
  };

  //Data  filled by the user
  const submitHandler = async (e) => {
    e.preventDefault();

    if (!contract) {
      setContractValid(false);
      return;
    }


    setRequestSent(true);
    const serial_number = data.serial_number;
    const email = data.user.email;
    const submitResponse = await dispatch(saveData(
      {
        orderId,
        name,
        apPaterno,
        apMaterno,
        phone,
        phoneCompany,
        calle,
        nroInterior,
        nroExterior,
        colonia,
        municipio,
        estado,
        cp,
        CFDI,
        condicion,
        totalPrice,
        RFC,
        CURP,
        aduana,
        fraccionVehiculo,
        motor: motorId,
        nroPuertas,
        color,
        km,
        serial_number,
        email,
        socialReason: razonSocial,
        fiscalStreet: calleFiscal,
        fiscalExternalNumber: nroExteriorFiscal,
        fiscalInternalNumber: nroInteriorFiscal,
        fiscalLocality: coloniaFiscal,
        fiscalCity: municipioFiscal,
        fiscalState: estadoFiscal,
        fiscalPostalCode: cpFiscal
      }
    ));
    setRequestSent(false);
    if (submitResponse.success) {
      toast.success("Información guardada", {
        hideProgressBar: true,
        autoClose: 6000,
      });
      setInformationSaved(true);
      history.push(`/home/quote/pay/${orderId}`);
    }
    else {
      toast.error(submitResponse)
    }
  }

  useEffect(() => {
    //fetch years when a model is selected
    if (cp && cp.length >= 5) {
      axios({
        url: `https://api.copomex.com/query/info_cp/${cp}?token=${process.env.REACT_APP_COPOMEX_TOKEN}`, //your url
        method: 'GET'
      }).then((response) => {
        if (response.status === 200 && response.data && response.data.length > 0 && response.data[0].error === false) {
          const address = response.data[0].response;
          setColonia(address.asentamiento);
          setMunicipio(address.municipio);
          setEstado(address.estado);
        }
        console.log(response)
      });
    }
  }, [cp]);


  const downloadHandler = (e) => {
    e.preventDefault()

    axios({
      url: `/api/download/${orderId}/`, //your url
      method: 'GET',
      Authorization: `Bearer ${localStorage.getItem("access")}`,
      responseType: 'blob', // important

    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${data.serial_number}.txt`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });


  }


  return (

    <>
      {true ? (
        <div>

          {loading ? (<Loader />
          ) : error ? (
            <h2> No disponible actualmente. Intenta generando una cotización </h2>
          ) : (

            <Container fluid>
              {!data.order ? (
                <>
                  <Row>
                    
                    <Col xl="4" lg="12">
                      <Card className="card-stats">
                        <Card.Body>
                          <Row>
                            <Col xs="2">

                              <div className="icon-big text-center icon-warning">
                                <i className="fas fa-receipt text-success"></i>
                              </div>

                            </Col>
                            <Col xs="10">
                              <div className="numbers">
                                <p className="card-category">Cotización</p>
                                <Card.Title as="h3">{totalPrice} MXN</Card.Title>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer>
                          <hr></hr>
                          <div className="stats">
                            <i className="fas fa-redo mr-1 mr-1"></i>
                            Actualizado ahora
                          </div>
                        </Card.Footer>
                      </Card>
                    </Col>

                    <Col xl="4" lg="12">
                      <Card className="card-stats">
                        <Card.Body>
                          <Row>
                            <Col xs="2">
                              <div className="icon-big text-center icon-warning">
                                <i className="fas fa-car text-success"></i>
                              </div>
                            </Col>
                            <Col xs="10">
                              <div className="numbers">
                                <div as="h5">
                                  <div className='mb-2 card-category'>Marca: {data.brand.name}</div>
                                  <div className='mb-2 card-category'>Modelo: {data.model.name}</div>
                                  <div className='card-category'>Año: {data.year.name}</div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer>
                          <hr></hr>
                          <div className="stats">
                            <i className="far fa-calendar-alt mr-1"></i>
                            Fecha de solicitud: {data.createdAt.substring(0, 10)} {data.createdAt.substring(12, 19)}
                          </div>
                        </Card.Footer>
                      </Card>
                    </Col>

                    <Col xl="4" lg="12">
                      <Card className="card-stats">
                        <Card.Body>
                          <Row>
                            <Col xs="2">
                              <div className="icon-big text-center icon-warning">
                                <i className="far fa-user-circle text-info"></i>
                              </div>
                            </Col>
                            <Col xs="10">
                              <div className="numbers">
                                <div as="h5">
                                  <div className='mb-2 card-category'>Email: {data.user.email}</div>
                                  <div className='mb-2 card-category'>Número de serie: {data.serial_number} </div>
                                  <div className=' card-category'>{data.isPaid ? `Folio de banco: ${data.mexPagoFolio}`
                                    : 'Folio de banco: -'} </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer>
                          <hr></hr>
                          <div className="stats">
                            <i className="far fa-calendar-alt mr-1"></i>
                            Fecha de solicitud: {data.createdAt.substring(0, 10)} {data.createdAt.substring(12, 19)}
                          </div>
                        </Card.Footer>
                      </Card>
                    </Col>
                  </Row>

                  <Row className='py-5'>
                    <Col lg="12">
                      <Steps progressDot size="small" current={step}>
                        <Steps.Step title="Inicio Cotización" />
                        <Steps.Step title="Guardo Datos" />
                        <Steps.Step title="Realizo Pago" />
                        <Steps.Step title="Revisión de documentos" />
                        <Steps.Step title="En espera de Titulo" />
                        <Steps.Step title="Titulo Recibido" />
                        <Steps.Step title="Validación ante aduana" />
                        <Steps.Step title="Programación para Cruce" />
                        <Steps.Step title="Importación Realizada" />
                      </Steps>
                    </Col>
                  </Row>


                  <Row className="py-5">
                    <Card >
                      <Card.Header>
                        <Card.Title as="h2" className='text-light'>Datos de Facturación</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Container>
                          <Row>
                            <Col lg={12}>
                              <Form onSubmit={submitHandler}>
                                <Row>

                                  <Col lg={4} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1" >
                                        Nombres
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="name"
                                          type="text"
                                          placeholder="Nombre(s)"
                                          required
                                          value={name ? name : ''}
                                          onChange={(e) => setName(e.target.value)}
                                          maxLength={30} />
                                        : <FormControl
                                          id="name"
                                          type="text"
                                          placeholder="Nombre(s)"
                                          required
                                          value={name ? name : ''}
                                          onChange={(e) => setName(e.target.value)}
                                          maxLength={30}
                                          readOnly />
                                      }
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1" >
                                        Apellido Paterno
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="Name"
                                          type="text"
                                          placeholder="Ingresa el apellido paterno"
                                          required
                                          value={apPaterno ? apPaterno : ''}
                                          onChange={(e) => setApPaterno(e.target.value)}
                                          maxLength={30} />
                                        : <FormControl
                                          id="Name"
                                          type="text"
                                          placeholder="Ingresa el apellido paterno"
                                          required
                                          value={apPaterno ? apPaterno : ''}
                                          onChange={(e) => setApPaterno(e.target.value)}
                                          maxLength={30}
                                          readOnly />
                                      }
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1" >
                                        Apellido materno
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="secondLastName"
                                          type="text"
                                          placeholder="Ingresa el apellido materno"
                                          required
                                          value={apMaterno ? apMaterno : ''}
                                          onChange={(e) => setApMaterno(e.target.value)}
                                          maxLength={30} />
                                        : <FormControl
                                          id="secondLastName"
                                          type="text"
                                          placeholder="Ingresa el apellido materno"
                                          required
                                          value={apMaterno ? apMaterno : ''}
                                          onChange={(e) => setApMaterno(e.target.value)}
                                          maxLength={30}
                                          readOnly />
                                      }
                                    </Form.Group>
                                  </Col>

                                  <Col lg={6} className="form-group py-2">
                                    <label
                                      className=" mb-1"
                                    >
                                      Teléfono
                                    </label>

                                    <InputGroup>

                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip
                                          id="button-tooltip-2">
                                          En caso de contacto.
                                        </Tooltip>}
                                      >
                                        {({ ref, ...triggerHandler }) => (
                                          <InputGroup.Text {...triggerHandler}
                                            className="bg-white rounded-0" id="phone" >
                                            <i ref={ref} className="fas fa-info-circle"></i>
                                          </InputGroup.Text>

                                        )}
                                      </OverlayTrigger>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="phone"
                                          type="text"
                                          placeholder="ej. 915 123 1234"
                                          value={phone ? phone : ''}
                                          onChange={(e) => setPhone(e.target.value)}
                                          required
                                          minLength={10}
                                          maxLength={10}
                                          onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} />
                                        : <FormControl
                                          id="phone"
                                          type="text"
                                          placeholder="ej. 915 123 1234"
                                          value={phone ? phone : ''}
                                          onChange={(e) => setPhone(e.target.value)}
                                          required
                                          minLength={10}
                                          maxLength={10}
                                          onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                          readOnly />}
                                    </InputGroup>

                                  </Col>

                                  <Col lg={6} className="py-2">
                                    <Form.Group>
                                      <label className=" mb-1" >
                                        Compañía telefónica
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="PhoneCompany"
                                          type="text"
                                          placeholder="Compañía telefónica"
                                          required
                                          value={phoneCompany ? phoneCompany : ''}
                                          onChange={(e) => setPhoneCompany(e.target.value)}
                                          maxLength={30} />
                                        : <FormControl
                                          id="PhoneCompany"
                                          type="text"
                                          placeholder="Compañía telefónica"
                                          required
                                          value={phoneCompany ? phoneCompany : ''}
                                          onChange={(e) => setPhoneCompany(e.target.value)}
                                          maxLength={30}
                                          readOnly />}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={6} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1" >
                                        Calle
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="Street"
                                          type="text"
                                          placeholder="Calle"
                                          required
                                          value={calle ? calle : ''}
                                          onChange={(e) => setCalle(e.target.value)}
                                          maxLength={60}
                                        /> : <FormControl
                                          id="Street"
                                          type="text"
                                          placeholder="Calle"
                                          required
                                          value={calle ? calle : ''}
                                          onChange={(e) => setCalle(e.target.value)}
                                          maxLength={60}
                                          readOnly />}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={3} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1" >
                                        Número exterior
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="extN"
                                          type="text"
                                          placeholder="Número exterior"
                                          required
                                          value={nroExterior ? nroExterior : ''}
                                          onChange={(e) => setNroExterior(e.target.value)}
                                          maxLength={20} />
                                        : <FormControl
                                          id="extN"
                                          type="text"
                                          placeholder="Número exterior"
                                          required
                                          value={nroExterior ? nroExterior : ''}
                                          onChange={(e) => setNroExterior(e.target.value)}
                                          maxLength={20}
                                          readOnly />}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={3} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1" >
                                        Número interior
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="intN"
                                          type="text"
                                          placeholder="Número interior"
                                          value={nroInterior ? nroInterior : ''}
                                          onChange={(e) => setNroInterior(e.target.value)}
                                          maxLength={20} />
                                        : <FormControl
                                          id="intN"
                                          type="text"
                                          placeholder="Número interior"
                                          value={nroInterior ? nroInterior : ''}
                                          onChange={(e) => setNroInterior(e.target.value)}
                                          maxLength={20}
                                          readOnly />
                                      }
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1" >
                                        C.P.
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="cp"
                                          type="text"
                                          placeholder="C.P."
                                          required
                                          value={cp ? cp : ''}
                                          onChange={(e) => setCP(e.target.value)}
                                          maxLength={5} />
                                        : <FormControl
                                          id="cp"
                                          type="text"
                                          placeholder="C.P."
                                          required
                                          value={cp ? cp : ''}
                                          onChange={(e) => setCP(e.target.value)}
                                          readOnly
                                          maxLength={5} />
                                      }
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1" >
                                        Colonia
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="colonia"
                                          type="text"
                                          placeholder="Colonia o Localidad"
                                          value={colonia ? colonia : ''}
                                          onChange={(e) => setColonia(e.target.value)}
                                          required
                                          maxLength={60} />
                                        : <FormControl
                                          id="colonia"
                                          type="text"
                                          placeholder="Colonia o Localidad"
                                          value={colonia ? colonia : ''}
                                          onChange={(e) => setColonia(e.target.value)}
                                          required
                                          readOnly
                                          maxLength={60} />
                                      }
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1" >
                                        Municipio
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="municipio"
                                          type="text"
                                          placeholder="Municipio o Alcaldía"
                                          required
                                          value={municipio ? municipio : ''}
                                          onChange={(e) => setMunicipio(e.target.value)}
                                          maxLength={60} />
                                        : <FormControl
                                          id="municipio"
                                          type="text"
                                          placeholder="Municipio o Alcaldía"
                                          required
                                          value={municipio ? municipio : ''}
                                          onChange={(e) => setMunicipio(e.target.value)}
                                          readOnly
                                          maxLength={60} />
                                      }
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1" >
                                        Estado
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="estado"
                                          type="text"
                                          placeholder="Estado"
                                          required
                                          value={estado ? estado : ''}
                                          onChange={(e) => setEstado(e.target.value)}
                                          maxLength={60} />
                                        : <FormControl
                                          id="estado"
                                          type="text"
                                          placeholder="Estado"
                                          required
                                          value={estado ? estado : ''}
                                          onChange={(e) => setEstado(e.target.value)}
                                          readOnly
                                          maxLength={60} />}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1" >
                                        Uso CFDI
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="CFDI"
                                          as="select"
                                          required
                                          value={CFDI ? CFDI : ''}
                                          onChange={(e) => setCFDI(e.target.value)}>
                                          <option value=''>Selecciona...</option>
                                          {cfdis?.map((cfdi) =>
                                            <option value={cfdi.cfdiUsageId}>
                                              {cfdi.name}
                                            </option>
                                          )}
                                        </FormControl>
                                        : <FormControl
                                          id="CFDI"
                                          as="select"
                                          required
                                          readOnly
                                          value={CFDI ? CFDI : ''}
                                          onChange={(e) => setCFDI(e.target.value)}>
                                          <option value=''>Selecciona...</option>
                                          {cfdis?.map((cfdi) =>
                                            <option value={cfdi.cfdiUsageId}>
                                              {cfdi.name}
                                            </option>
                                          )}
                                        </FormControl>
                                      }
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1" >
                                        FISICA/MORAL
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="fisica/moral"
                                          as="select"
                                          required
                                          onChange={(e) => setCondicion(e.target.value)}>
                                          <option value=''>Selecciona...</option>
                                          <option value='FISICA'>FISICA</option>
                                          <option value='MORAL'>MORAL</option>
                                        </FormControl>
                                        : <FormControl
                                          id="fisica/moral"
                                          as="select"
                                          required
                                          readOnly
                                          onChange={(e) => setCondicion(e.target.value)}>
                                          <option value=''>Selecciona...</option>
                                          <option value='FISICA'>FISICA</option>
                                          <option value='MORAL'>MORAL</option>
                                        </FormControl>}
                                    </Form.Group>
                                  </Col>

                                  {condicion === "MORAL" ?
                                    <Col lg={4} className="form-group py-2">
                                      <Form.Group>
                                        <label className=" mb-1" >
                                          Razón Social
                                        </label>
                                        {isAuthenticated
                                          ? <FormControl

                                            id="RazonSocial"
                                            type="text"
                                            placeholder="Razón Social"
                                            required
                                            value={razonSocial ? razonSocial : ''}
                                            onChange={(e) => setRazonSocial(e.target.value)} />
                                          : <FormControl
                                            id="RazonSocial"
                                            type="text"
                                            placeholder="Razón Social"
                                            required
                                            readOnly
                                            value={razonSocial ? razonSocial : ''}
                                            onChange={(e) => setRazonSocial(e.target.value)} />
                                        }
                                      </Form.Group>
                                    </Col> : <></>
                                  }
                                  <Col lg={4} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1">
                                        Registro Federal de Contribuyentes (RFC)
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="address" type="text"
                                          placeholder="Ingresa el RFC"
                                          value={RFC ? RFC : ''}
                                          onChange={(e) => setRFC(e.target.value)}
                                          required
                                          maxLength={13}
                                          pattern="^\S+$" />
                                        : <FormControl
                                          id="address" type="text"
                                          placeholder="Ingresa el RFC"
                                          value={RFC ? RFC : ''}
                                          onChange={(e) => setRFC(e.target.value)}
                                          required
                                          maxLength={13}
                                          pattern="^\S+$"
                                          readOnly />
                                      }
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1">
                                        Clave Única de Registro de Población (CURP)
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="address" type="text"
                                          placeholder="Ingresa el CURP"
                                          value={CURP ? CURP : ''}
                                          onChange={(e) => setCURP(e.target.value)}
                                          required
                                          maxLength={18}
                                          pattern="^\S+$" />
                                        : <FormControl
                                          id="address" type="text"
                                          placeholder="Ingresa el CURP"
                                          value={CURP ? CURP : ''}
                                          onChange={(e) => setCURP(e.target.value)}
                                          required
                                          maxLength={18}
                                          readOnly
                                          pattern="^\S+$" />
                                      }
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1">
                                        Domicilio Fiscal
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="fiscaladdress"
                                          as="select"
                                          required
                                          onChange={(e) => setFiscalAddress(e.target.value)}>
                                          <option value=''>Selecciona...</option>
                                          <option value='SameFiscal'>Mismo que el anterior</option>
                                          <option value='DifferentFiscal'>Distinto al anterior</option>
                                        </FormControl>
                                        : <FormControl
                                          id="fiscaladdress"
                                          as="select"
                                          required
                                          readOnly
                                          onChange={(e) => setFiscalAddress(e.target.value)}>
                                          <option value=''>Selecciona...</option>
                                          <option value='SameFiscal'>Mismo que el anterior</option>
                                          <option value='DifferentFiscal'>Distinto al anterior</option>
                                        </FormControl>
                                      }
                                    </Form.Group>
                                  </Col>

                                  {(fiscalAddress === "DifferentFiscal") ? (
                                    <div className='row'>
                                      <Col lg={6} className="form-group py-2">
                                        <Form.Group>
                                          <label className=" mb-1" >
                                            Calle Fiscal
                                          </label>
                                          {isAuthenticated
                                            ? <FormControl
                                              id="FiscalStreet"
                                              type="text"
                                              placeholder="Calle Fiscal"
                                              required
                                              value={calleFiscal ? calleFiscal : ''}
                                              onChange={(e) => setCalleFiscal(e.target.value)} />
                                            : <FormControl
                                              id="FiscalStreet"
                                              type="text"
                                              placeholder="Calle Fiscal"
                                              required
                                              readOnly
                                              value={calleFiscal ? calleFiscal : ''}
                                              onChange={(e) => setCalleFiscal(e.target.value)} />
                                          }
                                        </Form.Group>
                                      </Col>

                                      <Col lg={3} className="form-group py-2">
                                        <Form.Group>
                                          <label className=" mb-1" >
                                            Número exterior Fiscal
                                          </label>
                                          {isAuthenticated
                                            ? <FormControl
                                              id="FiscalextN"
                                              type="text"
                                              placeholder="Número exteriorFiscal"
                                              required
                                              value={nroExteriorFiscal ? nroExteriorFiscal : ''}
                                              onChange={(e) => setNroExteriorFiscal(e.target.value)} />
                                            : <FormControl
                                              id="FiscalextN"
                                              type="text"
                                              placeholder="Número exteriorFiscal"
                                              required
                                              readOnly
                                              value={nroExteriorFiscal ? nroExteriorFiscal : ''}
                                              onChange={(e) => setNroExteriorFiscal(e.target.value)} />
                                          }
                                        </Form.Group>
                                      </Col>

                                      <Col lg={3} className="form-group py-2">
                                        <Form.Group>
                                          <label className=" mb-1" >
                                            Número interior Fiscal
                                          </label>
                                          {isAuthenticated
                                            ? <FormControl
                                              id="intNFiscal"
                                              type="text"
                                              placeholder="Número interior Fiscal"
                                              value={nroInteriorFiscal ? nroInteriorFiscal : ''}
                                              onChange={(e) => setNroInteriorFiscal(e.target.value)} />
                                            : <FormControl
                                              id="intNFiscal"
                                              type="text"
                                              placeholder="Número interior Fiscal"
                                              value={nroInteriorFiscal ? nroInteriorFiscal : ''}
                                              onChange={(e) => setNroInteriorFiscal(e.target.value)}
                                              readOnly />
                                          }
                                        </Form.Group>
                                      </Col>

                                      <Col lg={4} className="form-group py-2">
                                        <Form.Group>
                                          <label className=" mb-1" >
                                            C.P. Fiscal
                                          </label>
                                          {isAuthenticated
                                            ? <FormControl
                                              id="cpFiscal"
                                              type="text"
                                              placeholder="C.P. Fiscal"
                                              required
                                              value={cpFiscal ? cpFiscal : ''}
                                              onChange={(e) => setCPFiscal(e.target.value)} />
                                            : <FormControl
                                              id="cpFiscal"
                                              type="text"
                                              placeholder="C.P. Fiscal"
                                              required
                                              readOnly
                                              value={cpFiscal ? cpFiscal : ''}
                                              onChange={(e) => setCPFiscal(e.target.value)} />
                                          }
                                        </Form.Group>
                                      </Col>

                                      <Col lg={4} className="form-group py-2">
                                        <Form.Group>
                                          <label className=" mb-1" >
                                            Colonia Fiscal
                                          </label>
                                          {isAuthenticated
                                            ? <FormControl
                                              id="coloniaFiscal"
                                              type="text"
                                              placeholder="Colonia o Localidad Fiscal"
                                              value={coloniaFiscal ? coloniaFiscal : ''}
                                              onChange={(e) => setColoniaFiscal(e.target.value)}
                                              required />
                                            : <FormControl
                                              id="coloniaFiscal"
                                              type="text"
                                              placeholder="Colonia o Localidad Fiscal"
                                              value={coloniaFiscal ? coloniaFiscal : ''}
                                              onChange={(e) => setColoniaFiscal(e.target.value)}
                                              readOnly
                                              required />}
                                        </Form.Group>
                                      </Col>

                                      <Col lg={4} className="form-group py-2">
                                        <Form.Group>
                                          <label className=" mb-1" >
                                            Municipio Fiscal
                                          </label>
                                          {isAuthenticated
                                            ? <FormControl
                                              id="municipioFiscal"
                                              type="text"
                                              placeholder="Municipio o Alcaldía Fiscal"
                                              required
                                              value={municipioFiscal ? municipioFiscal : ''}
                                              onChange={(e) => setMunicipioFiscal(e.target.value)} />
                                            : <FormControl
                                              id="municipioFiscal"
                                              type="text"
                                              placeholder="Municipio o Alcaldía Fiscal"
                                              required
                                              readOnly
                                              value={municipioFiscal ? municipioFiscal : ''}
                                              onChange={(e) => setMunicipioFiscal(e.target.value)} />
                                          }
                                        </Form.Group>
                                      </Col>

                                      <Col lg={4} className="form-group py-2">
                                        <Form.Group>
                                          <label className=" mb-1" >
                                            Estado Fiscal
                                          </label>
                                          {isAuthenticated
                                            ? <FormControl
                                              id="estadoFiscal"
                                              type="text"
                                              placeholder="Estado Fiscal"
                                              required
                                              value={estadoFiscal ? estadoFiscal : ''}
                                              onChange={(e) => setEstadoFiscal(e.target.value)} />
                                            : <FormControl
                                              id="estadoFiscal"
                                              type="text"
                                              placeholder="Estado Fiscal"
                                              required
                                              value={estadoFiscal ? estadoFiscal : ''}
                                              onChange={(e) => setEstadoFiscal(e.target.value)}
                                              readOnly />
                                          }
                                        </Form.Group>
                                      </Col>
                                    </div>
                                  ) : (<></>)}

                                  {frontINE ?
                                    (<Col lg={12} className="form-group py-2">
                                      <Form.Group className=" mb-1">
                                        <label>INE Vigente</label><br />
                                        <a href={frontINE} target="_blank" rel="noopener noreferrer">Ver</a> |
                                        {isAuthenticated ? <a style={{ cursor: "pointer", color: "#1DC7EA", textDecoration: "underline" }} onClick={() => { removeImage("frontINE") }}>Cambiar</a> : <></>}
                                      </Form.Group>
                                    </Col>) : (
                                      <Col lg={12} className="form-group py-2">
                                        <Form.Group className=" mb-1">
                                          <label>INE Vigente</label>
                                          {isAuthenticated
                                            ? <Form.Control
                                              id="frontINE"
                                              type="file"
                                              placeholder="INE ambos lados"
                                              onChange={frontIneHandler}
                                              accept=".pdf"
                                              required>
                                            </Form.Control>
                                            : <Form.Control
                                              id="frontINE"
                                              type="file"
                                              placeholder="INE ambos lados"
                                              onChange={frontIneHandler}
                                              accept=".pdf"
                                              required
                                              readOnly>
                                            </Form.Control>
                                          }
                                          {uploading1 && <ImageLoader />}
                                          <Form.Text muted>
                                            <small>Formato permitido: PDF</small>
                                          </Form.Text>
                                        </Form.Group>
                                      </Col>
                                    )
                                  }

                                  {CDMIC ?
                                    (<Col lg={12} className="form-group py-2">
                                      <Form.Group className=" mb-1">
                                        <label>Comprobante de domicilio</label><br />
                                        <a href={CDMIC} target="_blank" rel="noopener noreferrer">Ver</a> |
                                        {isAuthenticated ? <a style={{ cursor: "pointer", color: "#1DC7EA", textDecoration: "underline" }} onClick={() => { removeImage("cdmic") }}>Cambiar</a> : <></>}
                                      </Form.Group>
                                    </Col>) : (
                                      <Col lg={12} className="form-group py-2">
                                        <Form.Group >
                                          <label className=" mb-1">Comprobante de domicilio</label>
                                          {isAuthenticated
                                            ? <Form.Control
                                              id="address" type="file"
                                              onChange={cdmicHandler}
                                              accept=".pdf"
                                              required>
                                            </Form.Control>
                                            : <Form.Control
                                              id="address" type="file"
                                              onChange={cdmicHandler}
                                              accept=".pdf"
                                              required
                                              readOnly>
                                            </Form.Control>}
                                          {uploading3 && <ImageLoader />}
                                          <Form.Text muted>
                                            No mayor a 3 meses
                                          </Form.Text>
                                        </Form.Group>
                                        <Form.Text muted>
                                          <small>Formato permitido: PDF</small>
                                        </Form.Text>
                                      </Col>)}

                                  <hr className='my-3'></hr>

                                  <h3 className="mt-2 mb-3">Datos del vehículo</h3>

                                  <Col lg={3} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1" >
                                        Motor
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="motor"
                                          as="select"
                                          required
                                          value={motorId ? motorId : ''}
                                          onChange={(e) => setMotor(e.target.value)}>
                                          <option value=''>Selecciona...</option>
                                          {motors?.map((motor) =>
                                            <option value={motor.motorId}>
                                              {motor.name}
                                            </option>
                                          )}
                                        </FormControl>
                                        : <FormControl
                                          id="motor"
                                          as="select"
                                          required
                                          readOnly
                                          value={motorId ? motorId : ''}
                                          onChange={(e) => setMotor(e.target.value)}>
                                          <option value=''>Selecciona...</option>
                                          {motors?.map((motor) =>
                                            <option value={motor.motorId}>
                                              {motor.name}
                                            </option>
                                          )}
                                        </FormControl>}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={3} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1" >
                                        Número de puertas
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="puertas"
                                          as="select"
                                          required
                                          value={nroPuertas ? nroPuertas : ''}
                                          onChange={(e) => setNroPuertas(e.target.value)}>
                                          <option value=''>Selecciona...</option>
                                          {doors?.map((door) =>
                                            <option value={door.doorId}>
                                              {door.name}
                                            </option>
                                          )}
                                        </FormControl>
                                        : <FormControl
                                          id="puertas"
                                          as="select"
                                          required
                                          readOnly
                                          value={nroPuertas ? nroPuertas : ''}
                                          onChange={(e) => setNroPuertas(e.target.value)}>
                                          <option value=''>Selecciona...</option>
                                          {doors?.map((door) =>
                                            <option value={door.doorId}>
                                              {door.name}
                                            </option>
                                          )}
                                        </FormControl>}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={3} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1" >
                                        Color
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          value={color ? color : ''}
                                          onChange={(e) => setColor(e.target.value)}
                                          id="color"
                                          type="text"
                                          placeholder="Color del vehículo"
                                          required />
                                        : <FormControl
                                          value={color ? color : ''}
                                          onChange={(e) => setColor(e.target.value)}
                                          id="color"
                                          type="text"
                                          readOnly
                                          placeholder="Color del vehículo"
                                          required />}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={3} className="form-group py-2">
                                    <Form.Group>
                                      <label className=" mb-1" >
                                        Kilometraje
                                      </label>
                                      {isAuthenticated
                                        ? <FormControl
                                          id="km"
                                          as="select"
                                          required
                                          value={km ? km : ''}
                                          onChange={(e) => setKm(e.target.value)}>
                                          <option value=''>Selecciona...</option>
                                          {mileages?.map((mileage) =>
                                            <option value={mileage.mileageId}>
                                              {mileage.name}
                                            </option>
                                          )}
                                        </FormControl>
                                        : <FormControl
                                          id="km"
                                          as="select"
                                          required
                                          readOnly
                                          value={km ? km : ''}
                                          onChange={(e) => setKm(e.target.value)}>
                                          <option value=''>Selecciona...</option>
                                          {mileages?.map((mileage) =>
                                            <option value={mileage.mileageId}>
                                              {mileage.name}
                                            </option>
                                          )}
                                        </FormControl>}
                                    </Form.Group>
                                  </Col>

                                  {frontTitle ?
                                    (<Col lg={12} className="form-group py-2">
                                      <Form.Group className=" mb-1">
                                        <label>Título (frontal)</label><br />
                                        <a href={frontTitle} target="_blank" rel="noopener noreferrer">Ver</a> |
                                        {isAuthenticated ? <a style={{ cursor: "pointer", color: "#1DC7EA", textDecoration: "underline" }} onClick={() => { removeImage("frontTitle") }}>Cambiar</a> : <></> }
                                      </Form.Group>
                                    </Col>) : (
                                      <>
                                        <Col lg={12} className="form-group py-2">
                                          <Form.Group >
                                            <label className=" mb-1">Título del Vehículo (frontal)</label>
                                            {isAuthenticated
                                              ? <Form.Control
                                                id="frontTitle" type="file"
                                                placeholder="Titulo página frontal"
                                                onChange={frontTitleHandler}
                                                accept=".pdf"
                                                required>
                                              </Form.Control>
                                              : <Form.Control
                                                id="frontTitle" type="file"
                                                placeholder="Titulo página frontal"
                                                onChange={frontTitleHandler}
                                                accept=".pdf"
                                                required
                                                readOnly>
                                              </Form.Control>}
                                            {uploading4 && <ImageLoader />}
                                          </Form.Group>
                                          <Form.Text muted>
                                            <small>El título original se deberá presentar en la agencia aduanal del Estado en donde se realizará el trámite. Formato permitido: PDF</small>
                                          </Form.Text>
                                          <FloatingLabel controlId="floatingInput" label="Estado" className="mb-4">
                                            <Form.Control
                                              as="select"
                                              value={stateId ? stateId : ''}
                                              placeholder='Selecciona un estado...'
                                              onChange={(e) => setStateId(e.target.value)}
                                            >
                                              <option>Selecciona un estado...</option>
                                              {states?.map((state) =>
                                                <option value={state.stateId}>
                                                  {state.name}
                                                </option>
                                              )}
                                            </Form.Control>
                                          </FloatingLabel>
                                          {stateOffices?.map((office) =>
                                            <div>
                                              <p>{office.name}</p>
                                              <p>{office.address}</p>
                                              <a href={office.googleMapsLink} >Google maps</a>
                                            </div>
                                          )}
                                        </Col>
                                      </>
                                    )}

                                  {backTitle ?
                                    (<Col lg={12} className="form-group py-2">
                                      <Form.Group className=" mb-1">
                                        <label>Título del Vehículo (posterior)</label><br />
                                        <a href={backTitle} target="_blank" rel="noopener noreferrer">Ver</a> |
                                        {isAuthenticated ? <a style={{ cursor: "pointer", color: "#1DC7EA", textDecoration: "underline" }} onClick={() => { removeImage("backTitle") }}>Cambiar</a> : <></>}
                                      </Form.Group>
                                    </Col>) : (
                                      <Col lg={12} className="form-group py-2">
                                        <Form.Group >
                                          <label className=" mb-1">Título (posterior)</label>
                                          {isAuthenticated
                                            ? <Form.Control
                                              id="backTitle" type="file"
                                              placeholder="Titulo página posterior"
                                              onChange={backTitleHandler}
                                              accept=".pdf"
                                              required>
                                            </Form.Control>
                                            : <Form.Control
                                              id="backTitle" type="file"
                                              placeholder="Titulo página posterior"
                                              onChange={backTitleHandler}
                                              accept=".pdf"
                                              readOnly
                                              required>
                                            </Form.Control>
                                          }
                                          {uploading5 && <ImageLoader />}
                                        </Form.Group>
                                        <Form.Text muted>
                                          <small>Formato permitido: PDF</small>
                                        </Form.Text>

                                      </Col>)}
                                  {VIN ?
                                    (<Col lg={3} className="form-group py-2">
                                      <Form.Group className=" mb-1">
                                        <label>NUM. SERIE UBICADO EN TABLERO</label><br />
                                        <img src={VIN} alt="NUM. SERIE UBICADO EN TABLERO" className='img-fluid' /><br />
                                        {isAuthenticated ? <a style={{ cursor: "pointer", color: "#1DC7EA", textDecoration: "underline" }} onClick={() => { removeImage("vin") }}>Cambiar</a> : <></>}
                                      </Form.Group>
                                    </Col>) : (
                                      <Col lg={12} className="form-group py-2">
                                        <Form.Group >
                                          <label className=" mb-1">NUM. SERIE UBICADO EN TABLERO</label>
                                          {isAuthenticated
                                            ? <Form.Control
                                              id="VIN" type="file"
                                              placeholder="Subir VIN"
                                              onChange={vinHandler}
                                              accept="image/*"
                                              required>
                                            </Form.Control>
                                            : <Form.Control
                                              id="VIN" type="file"
                                              placeholder="Subir VIN"
                                              onChange={vinHandler}
                                              accept="image/*"
                                              readOnly
                                              required>
                                            </Form.Control>}
                                          {uploading6 && <ImageLoader />}
                                        </Form.Group>
                                        <Form.Text muted>
                                          <small>Números de serie ubicados en encima del tablero de la puerta del conductor. Formato permitido: imagen</small>
                                        </Form.Text>

                                      </Col>)}
                                  {frontVehicle ?
                                    (<Col lg={3} className="form-group py-2">
                                      <Form.Group className=" mb-1">
                                        <label>Foto del vehículo (delantera)</label><br />
                                        <img src={frontVehicle} alt="Foto del vehículo (delantera)" className='img-fluid' /><br />
                                        {isAuthenticated ? <a style={{ cursor: "pointer", color: "#1DC7EA", textDecoration: "underline" }} onClick={() => { removeImage("frontVehicle") }}>Cambiar</a> : <></>}
                                      </Form.Group>
                                    </Col>) : (
                                      <Col lg={12} className="form-group py-2">
                                        <Form.Group >
                                          <label className=" mb-1">Foto del vehículo (delantera)</label>
                                          {isAuthenticated
                                            ? <Form.Control
                                              id="frontVehicle" type="file"
                                              placeholder="Parte frontal del vehículo"
                                              onChange={frontVehicleHandler}
                                              accept="image/*"
                                              required>
                                            </Form.Control>
                                            : <Form.Control
                                              id="frontVehicle" type="file"
                                              placeholder="Parte frontal del vehículo"
                                              onChange={frontVehicleHandler}
                                              accept="image/*"
                                              required>
                                            </Form.Control>}
                                          {uploading7 && <ImageLoader />}
                                        </Form.Group>
                                        <Form.Text muted>
                                          <small>Formato permitido: imagen</small>
                                        </Form.Text>

                                      </Col>)}
                                  {backVehicle ?
                                    (<Col lg={3} className="form-group py-2">
                                      <Form.Group className=" mb-1">
                                        <label>Foto del vehículo (posterior)</label><br />
                                        <img src={backVehicle} alt="Foto del vehículo (posterior)" className='img-fluid' /><br />
                                        {isAuthenticated ? <a style={{ cursor: "pointer", color: "#1DC7EA", textDecoration: "underline" }} onClick={() => { removeImage("backVehicle") }}>Cambiar</a> : <></>}
                                      </Form.Group>
                                    </Col>) : (
                                      <Col lg={12} className="form-group py-2">
                                        <Form.Group >
                                          <label className=" mb-1">Foto del vehículo (posterior)</label>
                                          {isAuthenticated
                                            ? <Form.Control
                                              id="postVehicle" type="file"
                                              placeholder="Parte posterior del vehículo"
                                              onChange={backVehicleHandler}
                                              accept="image/*"
                                              required>
                                            </Form.Control>
                                            : <Form.Control
                                              id="postVehicle" type="file"
                                              placeholder="Parte posterior del vehículo"
                                              onChange={backVehicleHandler}
                                              accept="image/*"
                                              required
                                              readOnly>
                                            </Form.Control>}
                                          {uploading8 && <ImageLoader />}
                                        </Form.Group>
                                        <Form.Text muted>
                                          <small>Formato permitido: imagen</small>
                                        </Form.Text>
                                      </Col>)}
                                  {side1Vehicle ?
                                    (<Col lg={3} className="form-group py-2">
                                      <Form.Group className=" mb-1">
                                        <label>Foto del vehículo (lateral izquierdo)</label><br />
                                        <img src={side1Vehicle} alt="Foto del vehículo (posterior)" className='img-fluid' /><br />
                                        {isAuthenticated ? <a style={{ cursor: "pointer", color: "#1DC7EA", textDecoration: "underline" }} onClick={() => { removeImage("sideOne") }}>Cambiar</a> : <></>}
                                      </Form.Group>
                                    </Col>) : (
                                      <Col lg={12} className="form-group py-2">
                                        <Form.Group >
                                          <label className=" mb-1">Foto del vehículo (lateral izquierdo)</label>
                                          {isAuthenticated
                                            ? <Form.Control
                                              id="side1Vehicle" type="file"
                                              placeholder="Parte lateral izquierda del vehículo"
                                              onChange={side1Handler}
                                              accept="image/*"
                                              required>
                                            </Form.Control>
                                            : <Form.Control
                                              id="side1Vehicle" type="file"
                                              placeholder="Parte lateral izquierda del vehículo"
                                              onChange={side1Handler}
                                              accept="image/*"
                                              required>
                                            </Form.Control>}
                                          {uploading9 && <ImageLoader />}
                                        </Form.Group>
                                        <Form.Text muted>
                                          <small>Formato permitido: imagen</small>
                                        </Form.Text>
                                      </Col>)}
                                  {side2Vehicle ?
                                    (<Col lg={3} className="form-group py-2">
                                      <Form.Group className=" mb-1">
                                        <label>Foto del vehículo (lateral derecho)</label><br />
                                        <img src={side2Vehicle} alt="Foto del vehículo (lateral derecho)" className='img-fluid' /><br />
                                        {isAuthenticated ? <a style={{ cursor: "pointer", color: "#1DC7EA", textDecoration: "underline" }} onClick={() => { removeImage("sideTwo") }}>Cambiar</a> : <></>}
                                      </Form.Group>
                                    </Col>) : (
                                      <Col lg={12} className="form-group py-2">
                                        <Form.Group >
                                          <label className=" mb-1">Foto del vehículo (lateral derecho)</label>
                                          {isAuthenticated
                                            ? <Form.Control
                                              id="side2Vehicle" type="file"
                                              placeholder="Parte lateral derecha del vehículo"
                                              onChange={side2Handler}
                                              accept="image/*"
                                              required>
                                            </Form.Control>
                                            : <Form.Control
                                              id="side2Vehicle" type="file"
                                              placeholder="Parte lateral derecha del vehículo"
                                              onChange={side2Handler}
                                              accept="image/*"
                                              required>
                                            </Form.Control>}
                                          {uploading10 && <ImageLoader />}
                                        </Form.Group>
                                        <Form.Text muted>
                                          <small>Formato permitido: imagen</small>
                                        </Form.Text>
                                      </Col>)}
                                  {serialNumberImage ?
                                    (<Col lg={3} className="form-group py-2">
                                      <Form.Group className=" mb-1">
                                        <label>NUM. SERIE DENTRO DE LA PUERTA</label><br />
                                        <a href={serialNumberImage} target="_blank" rel="noopener noreferrer">Ver</a> |
                                        {isAuthenticated ? <a style={{ cursor: "pointer", color: "#1DC7EA", textDecoration: "underline" }} onClick={() => { removeImage("serialNumber") }}>Cambiar</a> : <></>}
                                      </Form.Group>
                                    </Col>) : (
                                      <Col lg={12} className="form-group py-2">
                                        <Form.Group >
                                          <label className=" mb-1">NUM. SERIE DENTRO DE LA PUERTA</label>
                                          {isAuthenticated
                                            ? <Form.Control
                                              id="doorBehicle" type="file"
                                              placeholder="Subir el número de serie"
                                              onChange={SerialNumberHandler}
                                              accept=".pdf"
                                              required>
                                            </Form.Control>
                                            : <Form.Control
                                              id="doorBehicle" type="file"
                                              placeholder="Subir el número de serie"
                                              onChange={SerialNumberHandler}
                                              accept=".pdf"
                                              required>
                                            </Form.Control>}
                                          {uploading11 && <ImageLoader />}
                                        </Form.Group>
                                        <Form.Text muted>
                                          <small>Números de serie ubicados en en el costado de la puerta del conductor.
                                            Formato permitido: PDF</small>
                                        </Form.Text>
                                      </Col>)}
                                  {mile ?
                                    (<Col lg={3} className="form-group py-2">
                                      <Form.Group className=" mb-1">
                                        <label>Foto de las millas</label><br />
                                        <img src={mile} alt="Foto del vehículo (lateral derecho)" className='img-fluid' /><br />
                                        {isAuthenticated ? <a style={{ cursor: "pointer", color: "#1DC7EA", textDecoration: "underline" }} onClick={() => { removeImage("mile") }}>Cambiar</a> : <></>}
                                      </Form.Group>
                                    </Col>) : (
                                      <Col lg={12} className="form-group py-2">
                                        <Form.Group >
                                          <label className=" mb-1">Foto de las millas</label>
                                          {isAuthenticated
                                            ? <Form.Control
                                              id="mile" type="file"
                                              placeholder="Subir millas"
                                              onChange={mileHandler}
                                              accept="image/*"
                                              required>
                                            </Form.Control>
                                            : <Form.Control
                                              id="mile" type="file"
                                              placeholder="Subir millas"
                                              onChange={mileHandler}
                                              accept="image/*"
                                              required>
                                            </Form.Control>}
                                          {uploading12 && <ImageLoader />}
                                        </Form.Group>
                                        <Form.Text muted>
                                          <small>Formato permitido: imagen</small>
                                        </Form.Text>
                                      </Col>)}
                                  {license ?
                                    (<Col lg={3} className="form-group py-2">
                                      <Form.Group className=" mb-1">
                                        <label>Licencia de conducir vigente</label><br />
                                        <a href={license} target="_blank" rel="noopener noreferrer">Ver</a> |
                                        {isAuthenticated ? <a style={{ cursor: "pointer", color: "#1DC7EA", textDecoration: "underline" }} onClick={() => { removeImage("license") }}>Cambiar</a> : <></>}
                                      </Form.Group>
                                    </Col>) : (
                                      <Col lg={12} className="form-group py-2">
                                        <Form.Group >
                                          <label className=" mb-1">Licencia de conducir vigente</label>
                                          {isAuthenticated
                                            ? <Form.Control
                                              id="deposit" type="file"
                                              placeholder="Subir licencia"
                                              accept=".pdf"
                                              onChange={licenseHandler}
                                              required>
                                            </Form.Control>
                                            : <Form.Control
                                              id="deposit" type="file"
                                              placeholder="Subir licencia"
                                              accept=".pdf"
                                              readOnly
                                              onChange={licenseHandler}
                                              required>
                                            </Form.Control>}
                                          {uploading13 && <ImageLoader />}
                                        </Form.Group>
                                        <Form.Text muted>
                                          <small>Formato permitido: PDF</small>
                                        </Form.Text>
                                      </Col>)}

                                  <Col lg={12} className="form-group py-2">
                                    <h3>Declaraciones:</h3>
                                    <p>Al proceder al pago yo:</p>
                                    <p>1. Declaro bajo protesta de decir verdad, que todos los datos presentados y documentos anexados son correctos y verdaderos</p>
                                    <p>2. Manifiesto que el Valor del vehículo declarado, es el valor comercial de conformidad con el Art.66 de la Ley Aduanera Vigente</p>
                                    <p>3. Acepto los términos, condiciones y declaraciones del presente contrato de prestación de servicios que celebro con Imax Melo Imagen S.A. de C.V.</p>
                                    <p>4. Se le notificará á a “El Cliente” cuando se detecten errores en la incorporación de información o de documentación que haya enviado para realizar su trámite. El proceso podrá continuar cuando el cliente realice las correcciones que se le indiquen. </p>
                                    <Row>
                                      <Col xs={12}>
                                        <Form.Check>
                                          <Form.Check.Input required />
                                          <Form.Check.Label>Acepto las declaraciónes anteriormente mencionadas.</Form.Check.Label>
                                          <Form.Control.Feedback type="invalid">Es necesario aceptar las condiciones antes de continuar.</Form.Control.Feedback>
                                        </Form.Check>
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Col lg={12}>
                                    {isAuthenticated && step < 2
                                      ? <Button
                                        variant="light"
                                        style={{ background: "#CCCCCC", width: "100%" }}
                                        type="button"
                                        className='btn-fill'
                                        block
                                        onClick={draftHandler}
                                      >
                                        Guardar Borrador
                                        {'    '}<i className="fas fa-long-arrow-alt-right ml-2"></i>
                                      </Button>
                                      : <></>}
                                  </Col>

                                  <hr className='my-3'></hr>

                                  <Col lg={12} className="form-group py-2">
                                    <label className=" mb-1"><b>Descargar contrato:</b>{informationSaved ? (<a href={process.env.REACT_APP_API_ENDPOINT_URL + '/api/Orders/Contract?id=' + orderId} target="_blank" rel="noopener noreferrer">Contrato</a>) : (<b>Para descargar tu contrato, por favor llena todos los campos</b>)}</label>
                                  </Col>
                                  {contract ?
                                    (<Col lg={3} className="form-group py-2">
                                      <Form.Group className=" mb-1">
                                        <label>Contrato Firmado</label><br />
                                        <a href={contract} target="_blank" rel="noopener noreferrer">Ver</a> |
                                        {isAuthenticated ? <a style={{ cursor: "pointer", color: "#1DC7EA", textDecoration: "underline" }} onClick={() => { removeImage("contract") }}>Cambiar</a> : <></>}
                                      </Form.Group>
                                    </Col>) : (
                                      <Col lg={12} className="form-group py-2">
                                        <Form.Group >
                                          <label className=" mb-1">Contrato Firmado</label>
                                          {isAuthenticated
                                            ? <Form.Control
                                              id="deposit" type="file"
                                              placeholder="Subir contrato firmado"
                                              accept=".pdf"
                                              onChange={contractHandler}
                                              isInvalid={!contractValid}>
                                            </Form.Control>
                                            : <Form.Control
                                              id="deposit" type="file"
                                              placeholder="Subir contrato firmado"
                                              accept=".pdf"
                                              readOnly
                                              onChange={contractHandler}
                                              isInvalid={!contractValid}>
                                            </Form.Control>}
                                          {uploading14 && <ImageLoader />}
                                          <Form.Control.Feedback type="invalid">
                                            Por favor sube tu contrato firmado.
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Text muted>
                                          <small>Formato permitido: .pdf</small>
                                        </Form.Text>
                                      </Col>)}
                                </Row>
                                {!requestSent ? (
                                  <div className="d-grid gap-2 mt-3 py-3">
                                    {isAuthenticated && step < 2
                                      ? <Button
                                        variant="light"
                                        style={{ background: "#39A388" }}
                                        type="submit"
                                        className='btn-fill'
                                        block
                                        onSubmit={submitHandler}
                                      >
                                        Proceder al pago
                                        {'    '}<i className="fas fa-long-arrow-alt-right ml-2"></i>
                                      </Button>
                                      : <></>
                                    }
                                  </div>
                                ) : (
                                  <div className="d-grid gap-2 mt-3 py-3">
                                    <Button
                                      variant="light"
                                      style={{ background: "#39A388" }}
                                      type="submit"
                                      className='btn-fill'
                                      block
                                      disabled>
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                      {"  "}
                                      Redirigiendo a plataforma de pago...
                                      {'    '}<i className="fas fa-long-arrow-alt-right ml-2"></i>
                                    </Button>
                                  </div>
                                )}
                              </Form>
                            </Col>
                          </Row>
                        </Container>
                      </Card.Body>
                    </Card>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Col lg="6" sm="12">
                      <Card className="card-stats">
                        <Card.Body>
                          <Row>
                            <Col xs="5">
                              <div className="icon-big text-center icon-warning">
                                {data.isPaid ? (
                                  <i className="far fa-circle text-success"></i>
                                ) : (
                                  <i className="far fa-circle text-warning"></i>
                                )}
                              </div>
                            </Col>
                            <Col xs="7">
                              <div className="numbers">
                                <p className="card-category">Estado</p>
                                <Card.Title as="h3">{data.isPaid ? 'Completado' : 'En proceso'}</Card.Title>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer>
                          <hr></hr>
                          <div className="stats">
                            {data.isPaid ? (<p>
                              <i className="far fa-calendar-alt mr-1"></i>
                              Pagado el:  {data.paidAt.substring(0, 10)} {data.paidAt.substring(12, 19)}  </p>
                            ) : (
                              <p><i className="far fa-calendar-alt mr-1"></i>Esperando confirmación</p>
                            )}
                          </div>
                        </Card.Footer>
                      </Card>
                    </Col>

                    <Col lg="6" sm="12">
                      <Card className="card-stats">
                        <Card.Body>
                          <Row>
                            <Col xs="5">

                              <div className="icon-big text-center icon-warning">
                                <i className="fas fa-receipt text-success"></i>
                              </div>

                            </Col>
                            <Col xs="7">
                              <div className="numbers">
                                <p className="card-category">Cotización</p>
                                <Card.Title as="h3">{totalPrice} MXN</Card.Title>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer>
                          <hr></hr>
                          <div className="stats">
                            <i className="fas fa-redo mr-1 mr-1"></i>
                            Actualizado ahora
                          </div>
                        </Card.Footer>
                      </Card>
                    </Col>

                    <Col lg="6" sm="12">
                      <Card className="card-stats">
                        <Card.Body>
                          <Row>
                            <Col xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="fas fa-car text-success"></i>
                              </div>
                            </Col>
                            <Col xs="7">
                              <div className="numbers">
                                <p as="h5">
                                  <div className='mb-2 card-category'>Marca: {data.brand.name}</div>
                                  <div className='mb-2 card-category'>Modelo: {data.model.name}</div>
                                  <div className='card-category'>Año: {data.year.year}</div>
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer>
                          <hr></hr>
                          <div className="stats">
                            <i className="far fa-calendar-alt mr-1"></i>
                            Fecha de solicitud: {data.createdAt.substring(0, 10)} {data.createdAt.substring(12, 19)}
                          </div>
                        </Card.Footer>
                      </Card>
                    </Col>

                    <Col lg="6" sm="12">
                      <Card className="card-stats">
                        <Card.Body>
                          <Row>
                            <Col xs="4">
                              <div className="icon-big text-center icon-warning">
                                <i className="far fa-user-circle text-info"></i>
                              </div>
                            </Col>
                            <Col xs="8">
                              <div className="numbers">
                                <p as="h5">
                                  <div className='mb-2 card-category'>Email: {data.user.email}</div>
                                  <div className='mb-2 card-category'>Número de serie: {data.serial_number} </div>
                                  <div className=' card-category'>Folio de banco: {data.order.folio_banco}</div>
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer>
                          <hr></hr>
                          <div className="stats">
                            <i className="far fa-calendar-alt mr-1"></i>
                            Fecha de solicitud: {data.createdAt.substring(0, 10)} {data.createdAt.substring(12, 19)}
                          </div>
                        </Card.Footer>
                      </Card>
                    </Col>
                  </Row>


                  <Row className="py-5">
                    <Card >
                      <Card.Header>
                        <Card.Title as="h2" className='text-light'>Resumen</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Container>
                          <Row>

                            <Col md={8}>
                              <ListGroup variant='flush'>
                                <ListGroup.Item>

                                  <h2 className="h2 text-uppercase my-3">Datos ingresados</h2>

                                  <p>
                                    <strong className="text-small text-uppercase">Importador: </strong>
                                    <p> {data.order.first_lastname} {data.order.second_lastname},
                                      {'  '}
                                      {data.order.name} </p>
                                  </p>

                                  <p>
                                    <strong className="text-small text-uppercase">Teléfono: </strong>
                                    <p>
                                      {data.order.phone}
                                      {'  '}
                                      {data.order.phone_company}
                                    </p>
                                  </p>

                                  <Row>
                                    <strong className="text-small text-uppercase">Dirección: </strong>
                                    <Col md={4} xs={12}>
                                      <p className='small'>
                                        Calle: {data.address.street}
                                      </p>

                                      <p className='small'>
                                        Número interior: {data.address.internal_number}
                                      </p>

                                      <p className='small'>
                                        Número exterior: {data.address.external_number}
                                      </p>

                                      <p className='small'>
                                        Colonia: {data.address.suburb}
                                      </p>
                                    </Col>

                                    <Col md={4} xs={12}>
                                      <p className='small'>
                                        Ciudad: {data.address.city}
                                      </p>

                                      <p className='small'>
                                        Municipio: {data.address.municipality}
                                      </p>

                                      <p className='small'>
                                        Localidad: {data.address.locality}
                                      </p>

                                      <p className='small'>
                                        Estado: {data.address.state}
                                      </p>
                                    </Col>

                                    <Col md={4} xs={12}>
                                      <p className='small'>
                                        C. P.: {data.address.postalCode}
                                      </p>

                                      <p className='small'>
                                        Uso CFDI: {data.address.CFDI}
                                      </p>

                                      <p className='small'>
                                        Física/Moral: {data.address.fisica_moral}
                                      </p>
                                    </Col>
                                  </Row>

                                </ListGroup.Item>

                                <ListGroup.Item>
                                  <h2 className="h2 text-uppercase my-3">Datos de factura</h2>
                                  <p>
                                    <strong className="text-small text-uppercase">Forma de pago: </strong>
                                    <p className='small '> {data.order.paymentMethod} </p>
                                  </p>

                                  <p>
                                    <strong className="text-small text-uppercase">RFC: </strong>
                                    <p className='small '> {data.order.RFC} </p>
                                  </p>

                                  <p>
                                    <strong className="text-small text-uppercase">CURP: </strong>
                                    <p className='small '> {data.order.CURP} </p>
                                  </p>

                                  <p>
                                    <strong className="text-small text-uppercase">Aduana-Ciudad: </strong>
                                    <p className='small '> {data.address.customs} </p>
                                  </p>

                                  <p>
                                    <strong className="text-small text-uppercase">Definitivo o regularización: </strong>
                                    <p className='small '> {data.address.customs_type} </p>
                                  </p>

                                </ListGroup.Item>

                                <ListGroup.Item>
                                  <h2 className="h2 text-uppercase my-3">Datos del vehículo</h2>
                                  {data.order.length === 0 ? (
                                    <h3> No disponible </h3>
                                  ) : (

                                    <ListGroup variant='flush'>

                                      <ListGroup.Item>
                                        <Row>
                                          <Col md={4} xs={12} className="mb-3 card-stats">
                                            <Image
                                              src="https://images.unsplash.com/photo-1518306727298-4c17e1bf6942?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1472&q=80"
                                              alt="Foto auto elegido IMAX"
                                              height="200"
                                              width="300"
                                              fluid rounded
                                            />
                                          </Col>

                                          <Col className="ml-2" md={4} xs={6}>
                                            <p>
                                              <strong className="text-small text-uppercase">Tipo: </strong>
                                              <p> {data.order.type} </p>
                                            </p>

                                            <p>
                                              <strong className="text-small text-uppercase">Fracción: </strong>
                                              <p className='small '> {data.order.fraction} </p>
                                            </p>

                                            <p>
                                              <strong className="text-small text-uppercase">Motor: </strong>
                                              <p className='small '> {data.order.motor} </p>
                                            </p>
                                          </Col>

                                          <Col className="ml-2" md={4} xs={6}>
                                            <p>
                                              <strong className="text-small text-uppercase">Puertas: </strong>
                                              <p className='small '> {data.order.doors} </p>
                                            </p>

                                            <p>
                                              <strong className="text-small text-uppercase">Color: </strong>
                                              <p className='small '> {data.order.color} </p>
                                            </p>

                                            <p>
                                              <strong className="text-small text-uppercase">Kms: </strong>
                                              <p className='small '> {data.order.kms} </p>
                                            </p>
                                          </Col>
                                        </Row>
                                      </ListGroup.Item>
                                    </ListGroup>
                                  )}

                                </ListGroup.Item>

                              </ListGroup>
                            </Col>

                            <Col md={4} lg={4}>
                              <Card className="my-3">
                                <ListGroup className="border-0 rounded-0 p-lg-4 bg-light" variant='flush'>
                                  <ListGroup.Item className="bg-light">
                                    <h4 className="text-uppercase mb-4">Documentos</h4>

                                    <Row>
                                      <Col><strong className="text-uppercase small font-weight-bold">Ine (frontal):</strong></Col>
                                      <Col className="text-center">
                                        <a href={data.front_INE} role="button" className="btn btm-sm btn-success btn-block">
                                          <span className="h6 text-muted"><i class="far fa-file-alt"></i></span>
                                        </a>
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>

                                  <ListGroup.Item className="bg-light">
                                    <Row>
                                      <Col><strong className="text-uppercase small font-weight-bold">Ine (posterior):</strong></Col>
                                      <Col className="text-center">
                                        <a href={data.back_INE} role="button" className="btn btm-sm btn-success btn-block">
                                          <span className="h6 text-muted"><i class="far fa-file-alt"></i></span>
                                        </a>
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>


                                  <ListGroup.Item className="bg-light">
                                    <Row>
                                      <Col><strong className="text-uppercase small font-weight-bold">Comp. domicilio:</strong></Col>
                                      <Col className="text-center">
                                        <a href={data.proof_of_address} role="button" className="btn btm-sm btn-success btn-block">
                                          <span className="h6 text-muted"><i class="far fa-file-alt"></i></span>
                                        </a>
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>


                                  <ListGroup.Item className="bg-light">
                                    <Row>
                                      <Col><strong className="text-uppercase small font-weight-bold">Título (frontal):</strong></Col>
                                      <Col className="text-center">
                                        <a href={data.front_title} role="button" className="btn btm-sm btn-success btn-block">
                                          <span className="h6 text-muted"><i class="far fa-file-alt"></i></span>
                                        </a>
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>

                                  <ListGroup.Item className="bg-light">
                                    <Row>
                                      <Col><strong className="text-uppercase small font-weight-bold">Título (posterior):</strong></Col>
                                      <Col className="text-center">
                                        <a href={data.back_title} role="button" className="btn btm-sm btn-success btn-block">
                                          <span className="h6 text-muted"><i class="far fa-file-alt"></i></span>
                                        </a>
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>

                                  <ListGroup.Item className="bg-light">
                                    <Row>
                                      <Col><strong className="text-uppercase small font-weight-bold">VIN:</strong></Col>
                                      <Col className="text-center">
                                        <a href={data.VIN_image} role="button" className="btn btm-sm btn-success btn-block">
                                          <span className="h6 text-muted"><i class="far fa-file-alt"></i></span>
                                        </a>
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>

                                  <ListGroup.Item className="bg-light">
                                    <Row>
                                      <Col><strong className="text-uppercase small font-weight-bold">Vehículo (delantera):</strong></Col>
                                      <Col className="text-center">
                                        <a href={data.vehicle_front_image} role="button" className="btn btm-sm btn-success btn-block">
                                          <span className="h6 text-muted"><i class="far fa-file-alt"></i></span>
                                        </a>
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>


                                  <ListGroup.Item className="bg-light">
                                    <Row>
                                      <Col><strong className="text-uppercase small font-weight-bold">Vehículo (trasera):</strong></Col>
                                      <Col className="text-center">
                                        <a href={data.vehicle_back_image} role="button" className="btn btm-sm btn-success btn-block">
                                          <span className="h6 text-muted"><i class="far fa-file-alt"></i></span>
                                        </a>
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>

                                  <ListGroup.Item className="bg-light">
                                    <Row>
                                      <Col><strong className="text-uppercase small font-weight-bold">Vehículo (lateral izquierdo):</strong></Col>
                                      <Col className="text-center">
                                        <a href={data.vehicle_side_image} role="button" className="btn btm-sm btn-success btn-block">
                                          <span className="h6 text-muted"><i class="far fa-file-alt"></i></span>
                                        </a>
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>

                                  <ListGroup.Item className="bg-light">
                                    <Row>
                                      <Col><strong className="text-uppercase small font-weight-bold">Vehículo (lateral derecho):</strong></Col>
                                      <Col className="text-center">
                                        <a href={data.vehicle_side2_image} role="button" className="btn btm-sm btn-success btn-block">
                                          <span className="h6 text-muted"><i class="far fa-file-alt"></i></span>
                                        </a>
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>


                                  <ListGroup.Item className="bg-light">
                                    <Row>
                                      <Col><strong className="text-uppercase small font-weight-bold">Número de serie:</strong></Col>
                                      <Col className="text-center">
                                        <a href={data.serial_number_image} role="button" className="btn btm-sm btn-success btn-block">
                                          <span className="h6 text-muted"><i class="far fa-file-alt"></i></span>
                                        </a>
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>


                                  <ListGroup.Item className="bg-light">
                                    <Row>
                                      <Col><strong className="text-uppercase small font-weight-bold">Millas:</strong></Col>
                                      <Col className="text-center">
                                        <a href={data.carmiles_image} role="button" className="btn btm-sm btn-success btn-block">
                                          <span className="h6 text-muted"><i class="far fa-file-alt"></i></span>
                                        </a>
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>

                                  <ListGroup.Item className="bg-light">
                                    <Row>
                                      <Col><strong className="text-uppercase small font-weight-bold">Licencia:</strong></Col>
                                      <Col className="text-center">
                                        <a href={data.license_image} role="button" className="btn btm-sm btn-success btn-block">
                                          <span className="h6 text-muted"><i class="far fa-file-alt"></i></span>
                                        </a>
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>

                                  <ListGroup.Item className="bg-light">
                                    <Row>
                                      <Col><strong className="text-uppercase small font-weight-bold">Depósito:</strong></Col>
                                      <Col className="text-center">
                                        <a href={data.deposit_image} role="button" className="btn btm-sm btn-success btn-block">
                                          <span className="h6 text-muted"><i class="far fa-file-alt"></i></span>
                                        </a>
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>
                                </ListGroup>
                              </Card>
                            </Col>
                          </Row>
                        </Container>
                      </Card.Body>
                    </Card>
                  </Row>

                  {userInfo.is_staff && (<div className="d-grid gap-2 mt-3 py-3">
                    <Button
                      variant="light"
                      style={{ background: "#39A388" }}
                      type="button"
                      className='btn-fill'
                      block
                      onClick={downloadHandler}
                    >
                      Descargar .txt
                      {'    '}<i className="fas fa-long-arrow-alt-right ml-2"></i>
                    </Button>
                  </div>)}

                </>
              )}
            </Container>
          )}

        </div>
      ) : (
        <Redirect to="/login" />
      )}
    </>
  )
};

// Get Data from redux store
const mapToStateProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

// Connect react component with redux store
export const QuoteScreen = connect(mapToStateProps)(QuoteInfoApp);

