import React, { useState } from "react";

// Router
import { Redirect, useHistory } from "react-router-dom";

// redux connect with react
import { connect } from "react-redux";

// Bootstrap
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Spinner,
  InputGroup
} from "react-bootstrap";


import { toast } from "react-toastify";

// Redux action
import { signup } from "../../store/actions/actions";

import * as css from '../../styles/app.css'

const SignupApp = ({ signup, isAuthenticated }) => {

  const [formData, setformData] = useState({
    email: "",
    name: "",
    password: "",
    re_password: "",
  });

  const history = useHistory()

  const [requestSent, setRequestSent] = useState(false);

  const [emailError, setEmailError] = useState("");

  const [passwordError, setPasswordError] = useState("");

  const { email, name, password, re_password } = formData;

  const handleChange = (e) =>
    setformData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === re_password) {
      setRequestSent(true);
      // send state to redux
      const log = await signup(email, name, password, re_password);

      if (log.status === 200 && log.data.success) {
        const message = "Fue enviado un link de activación a tu correo electrónico.";
        setEmailError("");
        setPasswordError("");
        setRequestSent(false);
        toast.success(message, {
          hideProgressBar: true,
          autoClose: 5000,
        });
        history.push('/login');
      } else {
        setRequestSent(false);
        if(log.data?.errors && log.data.errors.length > 0){
          toast.error(log.data.errors[0].message, {
            hideProgressBar: true,
            autoClose: 5000,
          });
        }
        else{
          toast.error("Error al crear tu usuario, intenta de nuevo");
        }
      }
    } else {
      toast.error("Las contraseñas deben coincidir, intenta de nuevo");
    }
  };

  // user IsAuthenticated
  // Redirect to homepage

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <>
    <div className="page-header header-filter">
      <div className="pageContent d-flex align-items-center min-vh-100">
        <Container>
          <Row xs={12} className="justify-content-center">
            <Col lg={5} xs={12}>
              <Card>
                <div className="card-header card-header-primary text-center">
                    <Card.Title><h2 className='mb-2 text-white'>Registro de cuenta</h2></Card.Title> 
                </div>
                <Card.Body className="p-4">
                  
                  <Form onSubmit={(e) => handleSubmit(e)}>
                  
                    <h5 className="text-muted mt-3">Correo electrónico</h5>
                    <InputGroup className="input-group-addon mb-3" >
                      
                        <InputGroup.Text id="basic-addon1">
                        <i className="fas fa-at text-muted"></i>
                        </InputGroup.Text> 
                        
                        <Form.Control
                        type="email"
                        name="email"
                        id="email"
                        placeholder="(ej. usuario@email.com)"
                        value={email || ""}
                        onChange={(e) => handleChange(e)}
                        required
                        isInvalid={!!emailError}
                      />
                      <Form.Control.Feedback type="invalid">
                          Este correo electrónico ya fue ingresado, intenta de nuevo con uno diferente.
                      </Form.Control.Feedback>
                    </InputGroup>
                    
                    <h5 className="text-muted mt-3">Nombre</h5>
                    <InputGroup className="input-group-addon mb-3">
                      
                      <InputGroup.Text id="basic-addon1">
                      <i className="far fa-user text-muted"></i>
                      </InputGroup.Text> 

                      <Form.Control
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Nombre"
                        value={name || ""}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </InputGroup>

                    <h5 className="text-muted mt-3">Contraseña</h5>
                    <InputGroup className="input-group-addon">
                      
                      <InputGroup.Text id="basic-addon1">
                      <i className="fas fa-lock text-muted"></i>
                      </InputGroup.Text> 

                      <Form.Control
                        type="password"
                        name="password"
                        id="password"
                        aria-describedby="passwordHelpBlock"
                        placeholder="Ingresa una contraseña"
                        value={password || ""}
                        onChange={(e) => handleChange(e)}
                        minLength="8"
                        isInvalid={!!passwordError}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Esta contraseña es de muy poca seguridad, por favor ingresa una nueva.
                      </Form.Control.Feedback>
                    </InputGroup>
                    

                    <Form.Text id="passwordHelpBlock" muted>
                      <small>La contraseña debe tener entre 8 y 20 caracteres, contener letras, números y/o caracteres especiales.</small>
                    </Form.Text>

                    <h5 className="text-muted mt-3">Confirmar contraseña</h5>
                    <InputGroup className="input-group-addon mb-3">
                      <InputGroup.Text id="basic-addon1">
                      <i className="fas fa-lock text-muted"></i>
                      </InputGroup.Text> 

                      <Form.Control
                        type="password"
                        name="re_password"
                        id="re_password"
                        placeholder="Confirma tu contraseña"
                        value={re_password || ""}
                        onChange={(e) => handleChange(e)}
                        minLength="8"
                        required
                      />
                    </InputGroup>

                    <Form.Group className="py-3 mt-3 mb-3 text-center">
                      {!requestSent ? (
                        <Button type="submit" className='text-dark' variant='secondary' value="Login">
                          Registrar
                        </Button>
                      ) : (
                        <Button className='text-dark' variant='success' disabled>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          {"  "}
                          Enviando link...
                        </Button>
                      )}
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    </>
  );
};

// Get Data from redux store
const mapStateToProps = (state) => ({
  // Check Authentication
  isAuthenticated: state.auth.isAuthenticated,
});

// Connect react component with redux store
export const Signup = connect(mapStateToProps, { signup })(SignupApp);
