import React from 'react'
import { useSelector } from 'react-redux'

function MexPago() {

    //Fecha actual
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    const fecha = hoy.toISOString();
    const ymd = fecha.substring(0, 10);
    const hms = fecha.substring(12, 19);
    var fechaAct= ymd.toString() + ' ' + hms.toString();

    const quoteDetails = useSelector(state => state.quoteDetails)
    const { data, } = quoteDetails

    return (
        <div>
            <form action={process.env.REACT_APP_API_MEXPAGO} method="post">
                    <label>Monto : </label>
                    <input type="input" name="monto" value={data?.price} readOnly />
                    <input type="hidden" name="noTransaccion" value={data?.serial_number} />
                    <input type="hidden" name="llave" value={process.env.REACT_APP_MEXPAGO_API_KEY} />
                    <input type="hidden" name="fecha" value={fechaAct} />
                    <input type="hidden" name="articulos" 
                    value={`{"articulos":[{"descripcion":"${data?.serial_number}", "monto": "${data?.price}"}]}`}/>
                    <input 
                    type="hidden" 
                    name="precargaDatos" 
                    value= {`{ 
                        "nombre": "${data?.firstName}",
                        "apPaterno": "${data?.lastName}",
                        "apMaterno": "${data?.mothersName}",
                        "celular": "${data?.phone}", 
                        "correo": "${data?.user?.email}",
                        "calle": "${data?.street}",
                        "numero": "${data?.externalNumber}", 
                        "colonia": "${data?.locality}",
                        "estado": "${data?.state}",
                        "codigoPostal": "${data?.postalCode}"
                        }`} />
                    <input 
                    type="hidden" 
                    name="pago_efectivo" 
                    value= {`{ "pago_efectivo" : [{"titulo" : "Referencia", "valor" : "${data?.serial_number}"}, { "titulo" : "Banco", "valor" : "BANORTE" }, { "titulo" : "Cuenta de Concentración Empresarial", "valor" : "153202" }] }`} />
                    <input type="hidden" name="enviarCorreo" value= "true" />
                    <input type="hidden" name="infoComercio" value= "true" />
                    <input type="hidden" name="lenguaje" value= "es" />
                    
                <button type="submit" style={{background: 'white', border: 'none',}}>
                    <img src="https://www.mexpago.com/img/btn-mexpago.png" alt='Pagar' />
                </button>
            </form>
        </div>
    )
}

export default MexPago
