import React, { useEffect } from 'react'
import { Table, Button, Col, Container, Card, Row, Form } from 'react-bootstrap'
// Router redirection
import { Link, Redirect } from "react-router-dom";

// redux connect with react
import { connect } from "react-redux";

import { useDispatch, useSelector } from 'react-redux'
import { Loader } from '../components/Loader/Loader'
import { listMyQuotes, listConstPrices } from "../store/actions/actions";

const ProfileScreenApp = ({ isAuthenticated }) => {
    

    const dispatch = useDispatch()

    const quoteListMy = useSelector(state => state.quoteListMy)
    const { loading: loadingQuotes, error: errorQuotes, quotes } = quoteListMy

    const auth = useSelector(state => state.auth)
    const { user } = auth


    useEffect(() => {
         
        dispatch(listMyQuotes());
        dispatch(listConstPrices(1));
    }, [dispatch])



    return (
      
      <>
      {isAuthenticated ? (
      
      <div>

            <Container fluid>
        <Row>
        <Col md="3">
            <Card className="card-user">
              <div className="card-image">
                <img
                  alt="..."
                  src={
                    require("../assets/img/photo-1431578500526-4d9613015464.jpeg")
                      .default
                  }
                ></img>
              </div>
              <Card.Body>
                <div className="author">
                  
                    <img
                      alt="..."
                      className="avatar border-gray"
                      src={require("../assets/img/faces/face-8.jpg").default}
                    ></img>
                    <h5 className="title">{user.name}</h5>
                  
                  <p className="description">Perfil de importador</p>
                </div>
              </Card.Body>
              <hr></hr>
            </Card>
          </Col>
          <Col md={9}>

            <Card>
              <Card.Header>
                <Card.Title as="h2" className='text-light'>Cotizaciones</Card.Title>
              </Card.Header>
              
              <Card.Body>
                {loadingQuotes ? (
                    <Loader />
                ) : errorQuotes ? (
                    <h4> No disponible actualmente. Intenta de nuevo más tarde </h4>
                ) : (
                            <Container>
                            <Table bordered striped hover responsive className='table-sm'>
                                <thead>
                                    <tr>
                                        <th className="border-0" scope="col">
                                        <strong className="text-small text-uppercase">Nro. de referencia</strong>
                                        </th>

                                        <th className="border-0" scope="col">
                                        <strong className="text-small text-uppercase">Fecha de creación</strong>
                                        </th>

                                        <th className="border-0" scope="col">
                                        <strong className="text-small text-uppercase">Total</strong>
                                        </th>

                                        <th className="border-0" scope="col">
                                        <strong className="text-small text-uppercase">Pago realizado</strong>

                                        </th>
                                        
                                        <th className="border-0" scope="col">
                                        <strong className="text-small text-uppercase">Cotizaciones</strong>
                                        </th>
                                        
                                    </tr>
                                </thead>

                                <tbody>
                                    {quotes.map(quote => (
                                        <tr key={quote.orderId}>
                                            <td>{quote.serial_number}</td>
                                            <td>{quote.createdAt.substring(0, 10)}</td>
                                            <td>{Number(quote.price).toFixed(2)} MXN</td>
                                            <td >
                                                {quote.isPaid ? (
                                                quote.paidAt.substring(0, 10)
                                               ) : (
                                                <i className='fas fa-times' style={{ color: 'red' }}></i>
                                            )}</td>
                                            <td>
                                                <Link to={`quote/${quote.orderId}`}>
                                                    <Button  className='btn-sm btn-success btn-block'
                                                    >Detalles</Button>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Container>
                        )}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        

{auth.userInfo.is_staff && (
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h2" className='text-light'>Editar perfil</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col className="pr-1" md="5">
                      <Form.Group>
                        <label>Compañía (desabilitado)</label>
                        <Form.Control
                          defaultValue="Creative Code Inc."
                          disabled
                          placeholder="Company"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="3">
                      <Form.Group>
                        <label>Nombre de usuario</label>
                        <Form.Control
                          defaultValue="michael23"
                          placeholder="Username"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label htmlFor="exampleInputEmail1">
                          Correo electrónico
                        </label>
                        <Form.Control
                          placeholder="Email"
                          type="email"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Primer nombre</label>
                        <Form.Control
                          defaultValue="Mike"
                          placeholder="Primer nombre"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Segundo nombre</label>
                        <Form.Control
                          defaultValue="Andrew"
                          placeholder="Last Name"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label>Dirección</label>
                        <Form.Control
                          defaultValue="Av. 1, nr. 8 Bl 1, Sc 1, Ap 09"
                          placeholder="Home Address"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>Ciudad</label>
                        <Form.Control
                          defaultValue="Mike"
                          placeholder="City"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="4">
                      <Form.Group>
                        <label>País</label>
                        <Form.Control
                          defaultValue="Andrew"
                          placeholder="Country"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label>Código postal</label>
                        <Form.Control
                          placeholder="ZIP Code"
                          type="number"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label>Sobre mí</label>
                        <Form.Control
                          cols="80"
                          defaultValue="..."
                          placeholder="Here can be your description"
                          rows="4"
                          as="textarea"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button
                    className=" pull-right"
                    type="submit"
                    variant="secondary"
                  >
                    Actualizar perfil
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
      )}
        
        </Container>
    </div>
     ) : (
      <Redirect to="/login" />
    )}
  </>
    )
};

// Get Data from redux store
const mapToStateProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

// Connect react component with redux store
export const ProfileScreen = connect(mapToStateProps)(ProfileScreenApp);