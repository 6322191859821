import React, { useState, useEffect } from "react";
// Router redirection
import { Redirect, useHistory } from "react-router-dom";

// redux connect with react
import { connect } from "react-redux";

// Bootstrap
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";

import { useDispatch } from 'react-redux';

import { updatePayment } from "../../store/actions/actions";

const SuccessApp = ({ match, isAuthenticated }) => {

  const url = new URL(window.location.href);
  const transactionNumber = url.searchParams.get("numeroTransaccion");
  const isPaid = url.searchParams.get("pago") === "aprobado";
  const authorizationNumber = url.searchParams.get("numeroAut");
  const mexPagoFolio = url.searchParams.get("folioMexPago");

  const dispatch = useDispatch()
  const history = useHistory()

  const [spinner, setSpinner] = useState(false);

  useEffect(() => {

    setSpinner(true);

    if (isPaid) {
      dispatch(updatePayment({
        isPaid,
        transactionNumber,
        authorizationNumber,
        mexPagoFolio
      }));
      setSpinner(false);
    }
  }, [dispatch, isPaid, transactionNumber, authorizationNumber, mexPagoFolio]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSpinner(true);
    history.push(`/home/profile`);
  };

  return (
    <>
      {isAuthenticated ? (


        <div className="d-flex align-items-center vh-100 py-3">
          <Container className="h-100">
            <Row className="align-items-center justify-content-center h-100">
              <Col lg={5}>
                <Card>
                  <Card.Body className="p-4">
                    <div className="text-center">
                      <h2 className="text-dark mb-3">Tu pago ha sido procesado satisfactoriamente</h2>
                    </div>
                    <Form onSubmit={(e) => handleSubmit(e)}>
                      <Form.Group className="text-center">
                        {!spinner ? (
                          <Button type="submit" variant="primary" value="Login">
                            Volver
                          </Button>
                        ) : (
                          <div>
                            <Spinner
                              as="span"
                              animation="border"
                              role="status"
                              aria-hidden="true"
                            />

                            <h3> Redirigiendo... </h3>
                          </div>
                        )}
                      </Form.Group>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

      ) : (
        <Redirect to="/login" />
      )}
    </>
  );
};

// Get Data from redux store
const mapToStateProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

// Connect react component with redux store
export const Success = connect(mapToStateProps)(SuccessApp);

